import {makeStyles} from "@material-ui/core/styles/index";

const style = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',

    },
    table: {
        minWidth: 650,

    },
    row: {

         border: '2px solid rgb(257,257,257)',

    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(0,100,150,0.05)',
    },

  },
    edit:{
        float: 'right',
        marginTop: 15,
        marginRight: 15,
    },

    form:{
        marginTop:'100px',
        marginLeft:'75px',
        marginRight:'10px',
        marginBottom:'25px',
        border: '1px solid lightgrey',
        padding: '4%',

    },
    editForm:{
        border: '1px solid lightgrey',
        padding: '4%',
        marginLeft: '3%',
        marginRight: '3%',
        marginBottom: '3%',
    },
    submit:{
        marginTop: '3%',
        backgroundColor: 'rgb(103, 58, 183)',
    },
    countError:{
        color:'red',
        float: "right",
        fontSize: "10px",
    },
    count: {
        color:'black',
        float: "right",
        fontSize: "10px",
    },
});

export default style;