import React from 'react';
import clsx from 'clsx';
import {  useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import style from './style.js';
import userImage from '../Images/person.png';
import Grid from '@material-ui/core/Grid';
import { Route, HashRouter, NavLink, } from 'react-router-dom';
import EmployerSearch from "../EmployerSearch";
import CreateEmployerComponent from "../CaptureEmployer";
import * as Cookies from "js-cookie";
import ApiService from "../../service/ApiService";


export default function Sidebar(props) {
    const classes = style();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    let loggedUser = "";
    const parseJwt = token => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    if (typeof Cookies.get("auth") !== "undefined") {
        loggedUser = parseJwt(Cookies.get("auth")).sub.displayName;
    }

    const welcomeMsg = "Hi, " + loggedUser;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function searchEmployer() {
        props.props.history.push('/');
    }
    function logoutClick() {
        let userSignOn = "";
        const parseJwt = token => {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return null;
            }
        };

        if (typeof Cookies.get("auth") !== "undefined") {
            userSignOn = parseJwt(Cookies.get("auth")).sub.employeeNumber;
        }
        var activityInput = {
            userRole: "TST",
            userSignOn: userSignOn,
            activityName: "newmarkets_logout"
        };
        ApiService.createUserActivity(activityInput)
            .then((res) => {
                console.log("User Activity Logged Out :", res.data.toString())
            });
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        props.props.history.push('/login');
        localStorage.clear();
        sessionStorage.clear();
    }

    function createStopOrder() {
        props.history.push('/createStopOrder');
    }

    return (
        <HashRouter>
        <Grid container component="main">
            <Grid item md={2}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    {/*<Toolbar >

                    </Toolbar>*/}
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    open={open}
                >
                <Toolbar style={{padding: '0'}}>
                <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuExpand, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                    <div className={classes.toolbar}>
                        <div>
                            <Typography variant="h6" noWrap  className={clsx( {
                                    [classes.hide]: !open,
                                })}>
                                New Markets &nbsp;&nbsp;
                            </Typography>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <IconButton onClick={handleDrawerClose} className={clsx(classes.menuHide, {
                                [classes.hide]: !open,
                            })}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                    </div>
                   </Toolbar>
                    <Divider className={classes.dividerLine}/>

                    <List className={classes.list}>

                        <ListItem button id="name">

                            <ListItemIcon className={clsx( {
                                [classes.img]: open, [classes.imgShrink]: !open,
                            })} >
                                <Avatar alt="userImage" src={userImage} className={classes.avatar} />
                               {/* <InboxIcon />*/}
                            </ListItemIcon>

                        </ListItem>
                          <ListItemText  className={clsx(classes.nametext, {
                                [classes.hide]: !open,
                            })} primary= {welcomeMsg} />
                    </List>

                    <Divider className={classes.dividerLine} />

                    <List className={classes.list}>
                        <NavLink
                            to={"/"} activeStyle={{ textDecoration: 'none' }}
                        >
                        <ListItem button id="employer">
                            <ListItemIcon><LocationCityIcon className={classes.employerIcon}/></ListItemIcon>
                            <ListItemText className={clsx(classes.menuButton, {
                                [classes.hide]: !open,
                            })}
                            primary="Employers"/>
                        </ListItem>
                        </NavLink>
                    </List>

                    <Divider className={classes.dividerLine} />
                <List className={classes.list}>
                            <ListItem button id="logout">
                                <ListItemIcon><ExitToAppIcon className={classes.employerIcon} onClick={logoutClick}/></ListItemIcon>
                                <ListItemText className={clsx(classes.menuButton, {
                                    [classes.hide]: !open,
                                })}
                                primary="Logout" onClick={logoutClick}/>
                            </ListItem>
                        </List>

               </Drawer>
            </div>
        </Grid>
        <Grid item md={10}>
            <main style={{marginLeft: '4%'}}>
                <Route exact path="/"  render={(props) => <EmployerSearch {...props} />} />
                <Route path="/createEmployer"  render={(props) => <CreateEmployerComponent {...props} />} />
            </main>
        </Grid>
    </Grid>
    </HashRouter>
    );
}