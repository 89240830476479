import {makeStyles} from "@material-ui/core/styles/index";

const style = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',

    },
    table: {
        minWidth: 650,

    },
    row: {

         border: '2px solid rgb(257,257,257)',

    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(0,100,150,0.05)',
    },

  },
  
});

export default style;