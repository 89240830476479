class Constants {

    fetchMonths() {
        const months = [ 'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December' ];
        return months;
    }

    fetchAllDayTypes() {
        const dayTypes = [
            {
                "id": -1,
                "dayType": "Monday"
            },
            {
                "id": -2,
                "dayType": "Tuesday"
            },
            {
                "id": -3,
                "dayType": "Wednesday"
            },
            {
                "id": -4,
                "dayType": "Thursday"
            },
            {
                "id": -5,
                "dayType": "Friday"
            },
            {
                "id": -6,
                "dayType": "Saturday"
            },
            {
                "id": -7,
                "dayType": "Sunday"
            },
            {
                "id": -8,
                "dayType": "Monday - Thursday"
            },
            {
                "id": -9,
                "dayType": "Monday - Friday"
            },
            {
                "id": -10,
                "dayType": "Monday - Saturday"
            },
            {
                "id": -11,
                "dayType": "Monday – Sunday"
            }
        ];
        return dayTypes;
    }
}
export default new Constants();