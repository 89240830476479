import { Component } from 'react'
import axios from 'axios';
import {API_CONFIG} from "./api-config";
import {MET_RETAIL_API_CONFIG} from "./api-config";


export class avatarService extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.params = {
            //apiUrl: "https://api.trello.com/1/members/me",
            apiUrl: API_CONFIG.concat('/trello/getMemberInformation'),
            apiQuotes: "https://type.fit/api/quotes",
            getUpFeeds: API_CONFIG.concat('/feeds/getCampaignFeeds')
        };
    }

/**Get user trello access */
GetTrelloUserAccessWarehouse=(data) => {
    return axios.get(API_CONFIG.concat('/trello/lookUpTrelloMemberbyUsernameWarehouse?domain_username=')  + data.username );
}


/*Get Access Via AD*/
GetUpAccessAD=(data) => {
    var session_url = MET_RETAIL_API_CONFIG;

    return axios.post(session_url, {}, {
        auth: {
            username: data.email,
            password: data.password
        },
        headers: {'Access-Control-Allow-Headers': '*'}
    });
}

/*Get Todays Inspirational Qoutes */
GetTodaysInspirationalQuote=() => {
    return axios.get(this.params.apiQuotes);
}

/*Get Digital lastest feeds */
GetDigitalLastestFeeds=() => {
    return axios.get(this.params.getUpFeeds);
}



}

export default avatarService


 
