import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Grid from "@material-ui/core/Grid/Grid";
import EditDialog from "./operatingHoursEditDialog";
import ApiService from "../../service/ApiService";
import axios from "axios";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
/*import employer from './EmployerTableJson';*/

function TableInfo(props) {
    const classes = style();
    const operatingHoursData = props.data;
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [fullWidth, setFullWidth] = React.useState(true);
    const employerId = props.employerId;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickSetHours = () => {
        props.onRefresh();
    };

    let listItems = operatingHoursData.map((d) => <li style={{listStyleType: 'none',marginBottom:'15px',}} key={d.dayType}>{d.dayType} ({d.startTime} - {d.endTime})</li>);


    return (
        <Paper className={classes.root}>
            <div>
                <Button className={classes.edit} onClick={handleClickOpen}>
                    Edit<EditRoundedIcon fontSize='small'/>
                </Button>

                <Dialog disableBackdropClick open={open} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}}
                        onClose={handleClose} aria-labelledby="form-dialog-title">
                    <Grid container component="main" >
                        <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                            <EditDialog operatingHours={operatingHoursData} employerId = {employerId} onClose={() => {handleClose();}} onSave={() => {handleClickSetHours();}}/>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>

            <div style={{height:390}}>
                {operatingHoursData.length > 0 ? (
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {operatingHoursData.map(hours => (
                                <TableRow className={classes.row} key={hours.empOperatingHoursId}>
                                    <TableCell component="th" scope="contact">
                                        {hours.dayType}
                                    </TableCell>
                                    <TableCell align="left" style={{textTransform: 'capitalize'}}>:&nbsp;&nbsp;{hours.startTime} - {hours.endTime}</TableCell>
                                </TableRow>
                                    ))}
                            </TableBody>
                        </Table>

                    ) : (
                    <div>
                        <br/>
                        Operating Hours data not available.
                    </div>
                )}
            </div>
        </Paper>
    );
}

class SimpleTable  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empHours : this.props.employerHours.employerOperatingHours,
        }
    }

    onRefresh = () => {
        let employerInput = {employerID: this.props.employerId};
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
                let empData = res.data.employerDetails.employerOperatingHours;
                this.setState({empHours : empData});
            });
    };

    render() {
        return (
            <div>
                <TableInfo data = {this.state.empHours} employerId = {this.props.employerId} onRefresh={() => {this.onRefresh();}}/>
            </div>
        );
    }
}
export default SimpleTable;
