import {makeStyles} from "@material-ui/core/styles/index";

const style = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fafafa',
        marginBottom:'3%',
         width:'120%',

    },
    employerTable:{
        transform: 'translate(0px, 10px)',
        width:'70%',
    },
    tableTabs:{
        color: 'white',
        backgroundColor: 'rgb(103, 58, 183)',
    },
    employerHeading:{
        color: 'rgb(103, 58, 183)',
        
    },

}));

export default style;