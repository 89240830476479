const Style  = ({
    MuiFormHelperTextRoot : {
        color: 'yellow',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',

    },
    textField: {
        marginLeft:'50px',
        marginRight:"10px",
        width: 880,
        backgroundColor: '#ffffff',
    },
    layout: {

        width: "auto",
        marginLeft: 5,
        marginRight: 5,
        // [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        //     width: 1100,
        //     // paddingTop: '8%',
        //     // paddingLeft: 15,
        // }
    },
    popUpLayout: {

    },
    button: {
        float: 'right',
        backgroundColor: '#673ab7',
        marginTop: 30,
        marginLeft: 5,
    },
    employerInfoHeading:{
        color: 'rgb(103, 58, 183)',
    },
    form:{
        margin: '7% 2%',
        border: '1px solid lightgrey',
        padding: '4%',

    },
    submit:{
        marginTop: '3%',
        backgroundColor: 'rgb(103, 58, 183)',
        width: '20%',
        marginLeft: '20%',
    },
    editSubmit: {
        marginTop: '3%',
        backgroundColor: 'rgb(103, 58, 183)',
        width: '20%',
    },
    newContact:{
        marginTop: '1%',
        marginBottom: '1%',
        backgroundColor: 'rgb(103, 58, 183)',
        float: 'right',
    },
    deleteContact:{
        marginTop: '2%',
        marginBottom: '1%',
        float: 'right',
    },
    contactHeading: {
        float: 'left',
        color: 'rgb(103, 58, 183)'
    },
    selectBox: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        float: 'left',
        marginLeft: "5px",
    },
    menu: {
        height: 400,
    }
});

export default Style;