import React, {Component, useState} from 'react';
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import style from '../CaptureEmployer/style.js';
import basicStyle from './style.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Typography from "@material-ui/core/Typography/Typography";
import Loader from "react-loader-spinner";
import ApiService from "../../service/ApiService";
import Box from '@material-ui/core/Box';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Cookies from "js-cookie";

let userSignOn = "";
const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


// And now we can use these
function SocialMediaEditDialogComponent (props) {
    const classes = style;
    const socialMediaData = props.data;
    const boxClasses = basicStyle();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const employerId = props.employerId;
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertText, setAlertText] = React.useState('');

    const handleClose = () => {
        props.onClose();
    };

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    function alertDialog (title, text){
        return (
            <Dialog
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                style={{marginTop: '-25%'}}
            >
                <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };


    const handleSave = () => {
        props.onSave();
    }

            let data = {'Facebook':'', 'Instagram':'','LinkedIn':'','Twitter':''};
            if(socialMediaData.length > 0) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        for(var j=0; j< socialMediaData.length; j++) {
                            if(socialMediaData[j].socialMediaType == key) {
                                data[key] = socialMediaData[j].urlName;
                            }
                        }
                    }
                }
            }

    return (

        <Grid container component="div">
            <Grid item md={10} style={{marginBottom: '25px', margin: '0 auto',marginTop: '4%'}}>
                <div className="App">
                    <CssBaseline />
                    <main style={classes.popUpLayout}>

                        <Formik enableReinitialize
                            initialValues={{Facebook:data.Facebook, Instagram:data.Instagram, LinkedIn:data.LinkedIn, Twitter:data.Twitter}}

                            onSubmit={(values,  { setSubmitting }) => {
                                console.log("Form is validated! Submitting the form...", values);
                                setIsLoaded(true);
                                let socialMediaRequest = [];
                                let remValues = values;
                                for (var key in values) {
                                    if (data.hasOwnProperty(key)) {
                                        for (var j = 0; j < socialMediaData.length; j++) {
                                            if (socialMediaData[j].socialMediaType == key) {
                                                socialMediaData[j].urlName = values[key];
                                                socialMediaRequest.push(socialMediaData[j]);
                                                delete remValues[key];
                                            }
                                        }
                                    }
                                }

                                for (var key in remValues) {
                                    if (data.hasOwnProperty(key)) {
                                        var newObj = {id: null,
                                                        employerId: employerId,
                                                        socialMediaType: key,
                                                        urlName: remValues[key],
                                                        userSignOn: null}
                                        socialMediaRequest.push(newObj);
                                    }
                                }
                                socialMediaRequest = socialMediaRequest.map(e => ({...e, userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber}));
                                ApiService.createUpdateSocialMedia(socialMediaRequest)
                                    .then(res => {
                                        var result = res;
                                        console.log("response : " , result);
                                        setIsLoaded(false);
                                        if(result.data.errorStatus === false) {
                                            handleSave();
                                            setAlertText(result.data.responseMessage);
                                            setAlertTitle('Success!');
                                            handleAlertOpen();
                                        }
                                        else {
                                            setAlertTitle('Error!');
                                            setAlertText(result.data.responseMessage);
                                            handleAlertOpen();
                                        }
                                    });
                        setSubmitting(false);
                }}

                validationSchema={Yup.object().shape({
                    Twitter: Yup.string()
                        .matches(/^https:\/\/www\.twitter\.com\/.*/, "Please enter valid twitter url"),
                    Facebook: Yup.string()
                        .matches(/^https:\/\/www\.facebook\.com\/.*/, "Please enter valid facebook url"),
                    Instagram: Yup.string()
                        .matches(/^https:\/\/www\.instagram\.com\/.*/, "Please enter valid instagram url"),
                    LinkedIn: Yup.string()
                        .matches(/^https:\/\/www\.linkedin\.com\/.*/, "Please enter valid linkedin url"),
                })}
                >
                {props => {
                    const {
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setSubmitting
                    } = props;
                    return (
                        <form noValidate onSubmit={handleSubmit} id="EditBasicInfoForm">

                            <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                                <Box  mb={3}>
                                    Edit Social Media Details
                                </Box>
                            </Typography>
                            {alertDialog()}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="Facebook"
                                        variant="outlined"
                                        id="Facebook"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        placeholder="https://www.facebook.com/profilename"
                                        defaultValue={data.Facebook}
                                        value={values.Facebook}
                                        label="Facebook"
                                        helperText={<Typography
                                            style={{color:'red',}}
                                        >
                                            {(errors.Facebook && touched.Facebook) && errors.Facebook}
                                        </Typography>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="Instagram"
                                        variant="outlined"
                                        id="Instagram"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="https://www.instagram.com/profilename"
                                        fullWidth
                                        defaultValue={data.Instagram}
                                        value={values.Instagram}
                                        label="Instagram"
                                        helperText={<Typography
                                            style={{color:'red',}}
                                        >
                                            {(errors.Instagram && touched.Instagram) && errors.Instagram}
                                        </Typography>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="LinkedIn"
                                        variant="outlined"
                                        id="LinkedIn"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        defaultValue={data.LinkedIn}
                                        value={values.LinkedIn}
                                        label="LinkedIn"
                                        placeholder="https://www.linkedin.com/profilename"
                                        helperText={<Typography
                                            style={{color:'red',}}
                                        >
                                            {(errors.LinkedIn && touched.LinkedIn) && errors.LinkedIn}
                                        </Typography>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="Twitter"
                                        variant="outlined"
                                        id="Twitter"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="https://www.twitter.com/profilename"
                                        fullWidth
                                        defaultValue={data.Twitter}
                                        value={values.Twitter}
                                        label="Twitter"
                                        helperText={<Typography
                                            style={{color:'red',}}
                                        >
                                            {(errors.Twitter && touched.Twitter) && errors.Twitter}
                                        </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <br/>

                            <div style={{marginBottom:'2%'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={classes.editSubmit}
                                    disabled={!setSubmitting}
                                >
                                    SAVE
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={classes.submit}
                                    onClick = {handleClose}
                                >
                                    CANCEL
                                </Button>

                            </div>
                            <div style={{textAlign: "center"}} height={50} width={40} >
                                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={isLoaded} />
                            </div>
                        </form>
                    );
                }}
                </Formik>
            {/* END OF FORMIK */}
            </main>
        </div>
    </Grid>
    </Grid>
    );
};

class socialMediaEditDialog  extends Component {
    constructor(props) {
        super(props);
    }
    handleClose = () => {
        this.props.onClose();
    };

    handleSave = () => {
        this.props.onSave();
    };

    render() {
        const socialMedias = this.props.empSocialMedia;
        const employerId = this.props.employerId;
        return (
            <div>
                <SocialMediaEditDialogComponent data = {socialMedias} props={this.props} onClose={() => {this.handleClose();}}
                                                employerId = {employerId} onSave={() => {this.handleSave();}}/>
            </div>
        );
    }
}
export default socialMediaEditDialog;
