import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Grid from "@material-ui/core/Grid/Grid";
import EditDialog from "./socialMediaEditDialog";
import ApiService from "../../service/ApiService";

function TableInfo(props) {
    const classes = style();
    let socialMedias = props.data;
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [fullWidth, setFullWidth] = React.useState(true);
    const empId = props.employerId;

    const handleClickSetEmpSocialMedia = () => {
        props.onRefresh();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

     return (
        <Paper className={classes.root}>
            <div>
                <Button className={classes.edit} onClick={handleClickOpen}>
                    Edit<EditRoundedIcon fontSize='small'/>
                </Button>

                <Dialog disableBackdropClick open={open} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <Grid container component="main" >
                        <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                            <EditDialog empSocialMedia={socialMedias} onClose={() => {handleClose();}} employerId = {empId} onSave={() => {handleClickSetEmpSocialMedia();}}/>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
            <div style={{height:390}}>
            {socialMedias.length > 0  ? (
            <Table  className={classes.table} aria-label="simple table">
                    <TableBody  >
                     {socialMedias.map(socialMedia => (
                      <TableRow className={classes.row} key={socialMedia.id}>
                        <TableCell component="th" scope="socialMedia" style={{width:'35%'}}>
                            {socialMedia.socialMediaType === "LinkedIn" && <LinkedInIcon style={{marginBottom: '-6px', marginRight: '2%'}}/>}
                            {socialMedia.socialMediaType === "Instagram" && <InstagramIcon style={{marginBottom: '-6px', marginRight: '2%'}}/>}
                            {socialMedia.socialMediaType === "Facebook" && <FacebookIcon style={{marginBottom: '-6px', marginRight: '2%'}}/>}
                            {socialMedia.socialMediaType === "Twitter" && <TwitterIcon style={{marginBottom: '-6px', marginRight: '2%'}}/>}

                            &nbsp;<span>{socialMedia.socialMediaType}</span>
                        </TableCell>

                        <TableCell align="left">
                            <div style={{display: 'inline-block'}}>:&nbsp;</div>
                            <div style={{display: 'inline-flex',width: '98%'}}>
                                <a href={socialMedia.urlName} target="_blank" rel="noopener noreferrer">{socialMedia.urlName}</a>
                            </div>
                        </TableCell>
                      </TableRow>
                     ))}
                    </TableBody>
                </Table>
                ) : (
                <div>
                    <br/>
                    Social media details not available
                </div>
                )}
        </div>
        </Paper>
    );
}

class SimpleTable  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empSocialMedia : this.props.employerSocialMedia,
        }
    }

    onRefresh = () => {
        let employerInput = {employerID: this.props.employerId};
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
                let socialMediaData = res.data.socialMediaDetails;
                this.setState({ empSocialMedia: socialMediaData });
            });
    };

    render() {
        const empId = this.props.employerId;
        return (
            <div>
                <TableInfo data = {this.state.empSocialMedia} employerId = {empId} onRefresh={() => {this.onRefresh();}}/>
            </div>
        );
    }
}
export default SimpleTable;
