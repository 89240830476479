import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import style from '../CaptureEmployer/style.js';
import ApiService from "../../service/ApiService";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker ,
} from '@material-ui/pickers';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Loader from "react-loader-spinner";
import * as Cookies from "js-cookie";
import Constants from "../../utils/Constants";
const classes = style;

const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


class hoursEditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opHours: props.operatingHours.length < 1 ? [{empOperatingHoursId: null, employerId: props.employerId, dayType:null, startTime: new Date(2020, 6, 4, 9, 0, 0, 567),
                endTime: new Date(2020, 6, 4, 17, 0, 0, 567), delete: null, startTimeError: '', endTimeError: '',
                dayTypeError: '', userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber}] : props.operatingHours,
            dayTypes: [],
            duplicateErrorFlag: false,
            isLoaded: false,
            employerID: props.employerId,
            removedContacts: [],
            mandatoryErrorFlag: false,
            alertOpen: false,
            alertTitle: '',
            alertText: '',
        };
    }

    handleAlertOpen = () => {
        this.setState({alertOpen: true});
    };

    handleAlertClose = () => {
        this.setState({alertOpen: false});
    };

    alertDialog(title, text){
        return (
                <Dialog
                    open={this.state.alertOpen}
                    onClose={this.handleAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick
                    style={{marginTop: '-25%'}}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };

    handleClose () {
        this.props.onClose();
    };

    handleSubmit() {
        let opHoursData = [...this.state.opHours];
        let count = 0;
        let mandatoryCount = 0;
        let userSignedOn = parseJwt(Cookies.get("auth")).sub.employeeNumber;

        for(let j = 0; j< opHoursData.length; j++) {
            opHoursData[j].userSignOn = userSignedOn ;
            opHoursData[j].employerId = this.state.employerID;
            if(opHoursData[j].startTimeError !== '' || opHoursData[j].endTimeError !== '' || opHoursData[j].dayTypeError !== '') {
                count = count + 1;
            }
            if(opHoursData[j].dayType == null){
                mandatoryCount = mandatoryCount + 1;
            }
        }

        let valueArr = opHoursData.map(function(value){ return value.dayType +  new Date(value.startTime).getHours()+ ":" + new Date(value.startTime).getMinutes()});
        let isDuplicate = valueArr.some(function(item, idx){
            return valueArr.indexOf(item) != idx
        });


        if(isDuplicate === true) {
            this.setState({alertText: 'You have duplicate operating hours. Please correct and save.'})
            this.setState({alertTitle: 'Error!'});
            this.handleAlertOpen();
        }
        else if(mandatoryCount > 0) {
            this.setState({alertText: 'You have not completed all mandatory fields. Please correct and save.'})
            this.setState({alertTitle: 'Error!'});
            this.handleAlertOpen();
        }
        else if(count > 0) {
            this.setState({alertText: 'You have validation errors. Please correct and save.'})
            this.setState({alertTitle: 'Error!'});
            this.handleAlertOpen();
        }
        else{
            console.log("Form is validated! Submitting the form...", opHoursData);
            this.setState({isLoaded:true});
            ApiService.updateEmployerOpHours(opHoursData)
                .then(res => {
                    let result = res;
                    this.setState({isLoaded:false})
                    if(result.data.errorStatus === false) {
                        this.props.onSave();
                        this.setState({alertText: result.data.responseMessage})
                        this.setState({alertTitle: 'Success!'});
                        this.handleAlertOpen();
                    }
                    else {
                        this.setState({alertText: result.data.responseMessage})
                        this.setState({alertTitle: 'Error!'});
                        this.handleAlertOpen();
                    }
                });
        }
    }

    convertAMPMTODate () {
        var that =this;
        let opHours = [...that.state.opHours];
        console.log("Form that.props.operatingHours.length", that.props.operatingHours.length);
        if(that.props.operatingHours.length > 0) {
            for(let j=0;j< opHours.length; j++) {
                let startTime = opHours[j].startTime;
                let endTime = opHours[j].endTime;
                let startNewTime = new Date();
                let endNewTime = new Date();
                let parts1 = String(startTime).match(/(\d+):(\d+) (AM|PM)/);
                let parts2 = String(endTime).match(/(\d+):(\d+) (AM|PM)/);
                if (parts1) {
                    let hours1 = parseInt(parts1[1]),
                        minutes1 = parseInt(parts1[2]),
                        tt1 = parts1[3];
                    if (tt1 === 'PM' && hours1 < 12) hours1 += 12;
                    startNewTime.setHours(hours1, minutes1, 0, 0);
                    console.log("that state", startNewTime)
                    opHours[j] = {...opHours[j], startTime: startNewTime};
                }
                if (parts2) {
                    let hours2 = parseInt(parts2[1]),
                        minutes2 = parseInt(parts2[2]),
                        tt2 = parts2[3];
                    if (tt2 === 'PM' && hours2 < 12) hours2 += 12;
                    endNewTime.setHours(hours2, minutes2, 0, 0);

                    console.log("that state", endNewTime)
                    opHours[j] = {...opHours[j], endTime: endNewTime};
                }
                opHours[j] = {...opHours[j], dayTypeError: ''};
                opHours[j] = {...opHours[j], startTimeError: ''};
                opHours[j] = {...opHours[j], endTimeError: ''};
            }

            that.setState({ opHours });
        }
    }

    componentDidMount() {
        var that = this;
        this.convertAMPMTODate();

        /*ApiService.fetchAllDayTypes()
            .then((function (res) {
                that.setState({dayTypes: res.data});
            }))
            .catch(function (error) {
                console.log(error);
            });*/
        that.setState({dayTypes: Constants.fetchAllDayTypes()})

    }

    handleStartTimeChange (i, date) {

        let opHours = [...this.state.opHours];
        //let newTime = (date.toTimeString().split(' ')[0]).toString();
        let a = new Date(date);
        let b = new Date(opHours[i].endTime);

        opHours[i] = {...opHours[i], startTime: date};
        if(a.getTime() === b.getTime()){
            opHours[i] = {...opHours[i], startTimeError: 'Start time and End time cannot be the same.'};
        }
        else {
            opHours[i] = {...opHours[i], startTimeError: ''};
            opHours[i] = {...opHours[i], endTimeError: ''};
        }
        this.setState({ opHours });

    };

    handleEndTimeChange (i, date) {

        let opHours = [...this.state.opHours];
        let a = new Date(opHours[i].startTime);
        let b = new Date(date);
        opHours[i] = {...opHours[i], endTime: date};
        if(a.getTime() === b.getTime()){
            opHours[i] = {...opHours[i], endTimeError: 'End time and Start time cannot be the same.'};
        }
        else {
            opHours[i] = {...opHours[i], startTimeError: ''};
            opHours[i] = {...opHours[i], endTimeError: ''};
        }
        this.setState({ opHours });
    }

    handleDayTypeChange(i, e) {
        let opHours = [...this.state.opHours];
        opHours[i] = {...opHours[i], dayType: e.target.value};
        if(e.target.value ==="" || e.target.value == null ){
            opHours[i] = {...opHours[i], dayTypeError: 'Please select day type'};
        }
        else {
            opHours[i] = {...opHours[i], dayTypeError: ''};
        }
        this.setState({ opHours });
    }

    addClick(){
        this.setState(prevState => ({
            opHours: [...prevState.opHours, {empOperatingHoursId: null, employerId: this.state.employerID, dayType:null, startTime:new Date(2020, 6, 4, 9, 0, 0, 567),
                endTime:new Date(2020, 6, 4, 17, 0, 0, 567), delete: null, startTimeError: '', endTimeError: '',
                dayTypeError: '', userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber}]
        }))
    }

    createUI(){
        return this.state.opHours.map((el, i) => {
            if(el.delete === null) {
            return(
                <div key={i}>
                {/*{i != 0 && <hr/>}*/}
                <br/>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" style={classes.selectBox}>
                            <InputLabel id={i + el.dayType}>
                                Day Type*
                            </InputLabel>
                            <Select
                                MenuProps={{style: classes.menu}}
                                value={el.dayType}
                                name="dayType"
                                onChange={this.handleDayTypeChange.bind(this, i)}
                                onBlur={this.handleDayTypeChange.bind(this, i)}
                                labelWidth={90}
                            >
                                {this.state.dayTypes.map(dayType =>
                                    <MenuItem key={dayType.id} value={dayType.dayType}>{dayType.dayType}</MenuItem>
                                )}

                            </Select>
                        </FormControl>
                        <div style={classes.errorText} name="dayTypeError">
                            {el.dayTypeError}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardTimePicker
                                    margin="normal"
                                    id={i + el.startTime}
                                    format="HH:mm"
                                    label="Time picker"
                                    value={el.startTime}
                                    onChange={this.handleStartTimeChange.bind(this, i)}
                                    keyboardbuttonbrops={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div style={classes.errorText} name="startTimeError">
                            {el.startTimeError}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardTimePicker
                                    margin="normal"
                                    id={i + el.endTime}
                                    format="HH:mm"
                                    label="Time picker"
                                    value={el.endTime}
                                    onChange={this.handleEndTimeChange.bind(this, i)}
                                    keyboardbuttonbrops={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div style={classes.errorText} name="endTimeError">
                            {el.endTimeError}
                        </div>
                    </Grid>


                    <Grid item xs={12} sm={2}>
                        {i !== 0 && <IconButton aria-label="delete" style={classes.deleteContact}
                                                onClick={this.removeClick.bind(this, i)}>
                            <DeleteIcon fontSize="large"/>
                        </IconButton>}
                    </Grid>

                </Grid>
                <br/>
            </div>
            )
        }})
    }


    removeClick= (i) =>{
        let opHours = [...this.state.opHours];
        if(opHours[i].empOperatingHoursId !== null) {
            opHours[i] = {...opHours[i], delete: true};
        }
        else {
            opHours.splice(i, 1);
        }
        this.setState({opHours});
    }

    render() {

        return (
            <Grid container component="div">
                <Grid item md={10} style={{marginBottom: '25px', margin: '0 auto',marginTop: '4%'}}>
                    <div className="App">
                        <CssBaseline />
                        <main style={classes.popUpLayout}>
                            <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                                <Box  mb={3}>
                                    Edit Employer Operating Hours
                                </Box>
                            </Typography>
                            {this.createUI()}
                            {this.alertDialog()}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick = {this.addClick.bind(this)}
                                style={classes.newContact}
                            >
                                Add New
                            </Button>
                            <br/>
                            <br/>
                            <div style={{marginBottom:'2%',marginLeft: '30%',}}>
                                <Button
                                    onClick = {() => {this.handleSubmit();}}
                                    variant="contained"
                                    color="primary"
                                    style={classes.editSubmit}
                                >
                                    SAVE
                                </Button>

                                <Button
                                    onClick = {() => {this.handleClose();}}
                                    variant="contained"
                                    color="primary"
                                    style={classes.submit}
                                >
                                    CANCEL
                                </Button>

                            </div>

                            <div style={{textAlign: "center"}} height={50} width={40} >
                                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={this.state.isLoaded} />
                            </div>
                        </main>
                    </div>
                </Grid>
            </Grid>
        )}
}
export default hoursEditDialog;
