import React, {Component, useState} from 'react';
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import style from '../CaptureEmployer/style.js';
import basicStyle from './style.js';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Typography from "@material-ui/core/Typography/Typography";
import Loader from "react-loader-spinner";
import ApiService from "../../service/ApiService";
import axios from "axios";
import Box from '@material-ui/core/Box';
import { Multiselect } from 'multiselect-react-dropdown';
import * as Cookies from "js-cookie";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

let userSignOn = "";
const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


// And now we can use these
function BenefitsEditDialogComponent (props) {
    const classes = style;
    const data = props.data;
    const boxClasses = basicStyle();
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedMedicalAids, setSelectedMedicalAids] = useState(props.data.medicalAids);
    const [selectedPensionFunds, setSelectedPensionFunds] = useState(props.data.pensionFunds);
    const medicalAidList = props.medicalAids;
    const pensionFundList = props.pensionFunds;
    let employerIdVal = data.employerID;
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertText, setAlertText] = React.useState('');

    const handleClose = () => {
        props.onClose();
    };

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    function alertDialog (title, text){
        return (
            <Dialog
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                style={{marginTop: '-25%'}}
            >
                <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleSave = () => {
        props.onSave();
    }

    function onSelectMedicalAid(selectedList, selectedItem) {
        selectedMedicalAids.push(selectedItem);
        setSelectedMedicalAids(selectedMedicalAids);
    }

    function onRemoveMedicalAid(selectedList, removedItem) {
        if(removedItem.id !== null) {
            let index = selectedMedicalAids.findIndex(x => x.id ===removedItem.id);
            selectedMedicalAids[index].delete = true;
        }
        else {
            let index = selectedMedicalAids.findIndex(x => x.medicalAidId ===removedItem.medicalAidId);
            selectedMedicalAids.splice(index, 1);
        }
    }

    function onSelectPensionFund(selectedList, selectedItem) {
        selectedPensionFunds.push(selectedItem);
        setSelectedPensionFunds(selectedPensionFunds);
    }

    function onRemovePensionFund(selectedList, removedItem) {
        if(removedItem.id !== null) {
            let index = selectedPensionFunds.findIndex(x => x.id ===removedItem.id);
            selectedPensionFunds[index].delete = true;
        }
        else {
            let index = selectedPensionFunds.findIndex(x => x.pensionFundId ===removedItem.pensionFundId);
            selectedPensionFunds.splice(index, 1);
        }
    }

    return (

        <Grid container component="div">
            <Grid item md={10} style={{marginBottom: '25px', margin: '0 auto',marginTop: '4%'}}>
                <div className="App">
                    <CssBaseline />
                    <main style={classes.popUpLayout}>

                        <Formik enableReinitialize
                            initialValues={{}}

                            onSubmit={(values,  { setSubmitting }) => {
                                setIsLoaded(true);
                                let medAids = selectedMedicalAids;
                                let pensFunds = selectedPensionFunds;

                                medAids.map(e => (e.userSignOn = parseJwt(Cookies.get("auth")).sub.employeeNumber,
                                                    e.employerID = employerIdVal));
                                pensFunds.map(e => (e.userSignOn = parseJwt(Cookies.get("auth")).sub.employeeNumber,
                                                     e.employerID = employerIdVal));

                                var benefits = {employerID: employerIdVal, medicalAids: medAids, pensionFunds: pensFunds};
                                console.log("Form is validated! Submitting the form...", benefits);
                                if(selectedMedicalAids.length === 0 && selectedPensionFunds.length === 0 ) {
                                    setAlertTitle('Error!');
                                    setAlertText("You have not selected any values. Please select medical aid or pension fund and resave.");
                                    handleAlertOpen();
                                    setIsLoaded(false);
                                }
                                else {
                                    ApiService.createUpdateBenefits(benefits)
                                        .then(res => {
                                            var result = res;
                                            setIsLoaded(false);
                                            if (result.data.errorStatus === false) {
                                                handleSave();
                                                setAlertText(result.data.responseMessage);
                                                setAlertTitle('Success!');
                                                handleAlertOpen();
                                            }
                                            else {
                                                setAlertTitle('Error!');
                                                setAlertText(result.data.responseMessage);
                                                handleAlertOpen();
                                            }
                                        });
                                    setSubmitting(false);
                                }
                            }}

                            validationSchema={Yup.object().shape({

                            })}
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    setSubmitting
                                } = props;
                                return (
                                    <form noValidate onSubmit={handleSubmit} id="EditBasicInfoForm">

                                        <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                                              <Box  mb={3}>
                                                  Edit Employee Benefits
                                              </Box>
                                        </Typography>
                                        {alertDialog()}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Multiselect
                                                    options={medicalAidList} // Options to display in the dropdown
                                                    onSelect={onSelectMedicalAid} // Function will trigger on select event
                                                    onRemove={onRemoveMedicalAid} // Function will trigger on remove event
                                                    closeOnSelect = {true}
                                                    placeholder = "Please Select Medical Schemes"
                                                    displayValue="medicalSchemeName" // Property name to display in the dropdown options
                                                    showCheckbox = {true}
                                                    selectedValues = {selectedMedicalAids}
                                                    closeIcon = {"circle"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} style={{marginTop: '25px'}}>
                                                <Multiselect
                                                    options={pensionFundList} // Options to display in the dropdown
                                                    onSelect={onSelectPensionFund} // Function will trigger on select event
                                                    onRemove={onRemovePensionFund} // Function will trigger on remove event
                                                    displayValue="pensionFundName" // Property name to display in the dropdown options
                                                    placeholder = "Please Select Pension Funds"
                                                    closeOnSelect = {true}
                                                    showCheckbox = {true}
                                                    selectedValues = {selectedPensionFunds}
                                                    closeIcon = {"circle"}
                                                />
                                            </Grid>


                                        </Grid>
                                        <br/>

                                        <div style={{marginBottom:'2%'}}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={classes.editSubmit}
                                                disabled={!setSubmitting}
                                            >
                                                SAVE
                                            </Button>

                                            <Button
                                                onClick = {handleClose}
                                                variant="contained"
                                                color="primary"
                                                style={classes.submit}
                                            >
                                                CANCEL
                                            </Button>

                                        </div>
                                        <div style={{textAlign: "center"}} height={50} width={40} >
                                            <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={isLoaded} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                        {/* END OF FORMIK */}
                    </main>
                </div>
            </Grid>
        </Grid>
);
};

class benefitsEditDialog  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            medicalAidList: this.props.medicalAidList,
            pensionFundList: this.props.pensionFundList,
        };
    }
    handleClose = () => {
        this.props.onClose();
    };

    handleSave = () => {
        this.props.onSave();
    };



    render() {
        const benefitDetails = this.props.empBenefits;
        return (
            <div>
                <BenefitsEditDialogComponent data = {benefitDetails} medicalAids = {this.state.medicalAidList}
                                             pensionFunds = {this.state.pensionFundList} onSave={() => {this.handleSave();}} onClose={() => {this.handleClose();}}/>
            </div>
        );
    }
}
export default benefitsEditDialog;
