import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EditDialog from './basicInfoEditDialog.js';
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import ApiService from "../../service/ApiService";

function createData(employerInfo, employerValues) {
    return { employerInfo, employerValues};
}

function TableInfo(props) {
    const classes = style();
    let employer = props.data;
    let rows = [];

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [fullWidth, setFullWidth] = React.useState(true);
    const empId = props.employerId;

    rows = [
        createData('Group', employer.employerAdditionalInfo.employerGroup.toString() === 'true' ? 'Yes' : 'No'),
        createData('Employer Sector', employer.sectorName),
        createData('Entity Type', employer.employerAdditionalInfo.entityType),
        createData('Multinational Company', employer.employerAdditionalInfo.multinationalCompany.toString() === 'true' ? 'Yes' : 'No'),
        createData('Reg. Number', employer.companyRegNumber),
        createData('Operational Status', employer.employerAdditionalInfo.operationalStatus),
        createData('VAT Number', employer.vatNumber),
        createData('TAX Number', employer.employerAdditionalInfo.taxNumber),
        createData('Number of Employees', employer.employerAdditionalInfo.noEmployed),
        createData('Financial Year End Month', employer.employerAdditionalInfo.financialYearEndMonth),
        createData('Employee Notes', employer.employerAdditionalInfo.employerNotes),
        createData('Nature of Business', employer.employerAdditionalInfo.natureOfBusiness),
        createData('Telephone Number', employer.employerAdditionalInfo.telephoneNumber),
        createData('Fax Number', employer.employerAdditionalInfo.faxNumber),
        createData('Web Address', employer.webAddress),
        createData('E-mail Address', employer.employerAdditionalInfo.emailAddress),
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickSetBasicInfo = () => {
        props.onRefresh();
    };

    const handleClose = () => {
        setOpen(false);
    };

    {/*employer.employerDetails.employerOperatingHours.length > 0? JSON.stringify(employer.employerDetails.employerOperatingHours) : ''*/}



/*
    const operatingHoursData = employer.employerOperatingHours;
    const listItems = operatingHoursData.map((d) => <li style={{listStyleType: 'none',marginBottom:'15px',}} key={d.dayType}>{d.dayType} ({d.startTime} - {d.endTime})</li>);
*/

    return (
        <Paper className={classes.root}>
            <div>
              <Button className={classes.edit} onClick={handleClickOpen}>
                Edit<EditRoundedIcon fontSize='small'/>
              </Button>

              <Dialog disableBackdropClick open={open} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleClose} aria-labelledby="form-dialog-title">
                  <Grid container component="main" >
                      <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                        <EditDialog  empBasicInfo={employer} onClose={() => {handleClose();}} onSave={() => {handleClickSetBasicInfo();}}/>
                      </Grid>
                  </Grid>
              </Dialog>
            </div>


            <div style={{height:390}}>
            <Table  className={classes.table} aria-label="simple table">
                {/*<TableHead >
                    <TableRow >
                        <TableCell ></TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>*/}
                <TableBody >
                    {rows.map(row => (
                     <TableRow className={classes.row} key={row.employerInfo}>
                         <TableCell component="th" scope="row" style={{width:'35%'}}>
                             {row.employerInfo}
                         </TableCell>
                         <TableCell align="left" >
                             <div style={{display: 'inline-block'}}>:&nbsp;</div>
                             <div style={{display: 'inline-flex',width: '98%'}}>{row.employerValues}</div>
                         </TableCell>
                     </TableRow>
                    ))}
                    {/*<TableRow className={classes.row}>
                    <TableCell component="th" scope="row" style={{width:'35%'}}>
                     Operating Hours
                    </TableCell>
                        <TableCell align="left" style={{textTransform: 'capitalize'}}>
                            <div style={{display: 'inline-block'}}>:&nbsp;</div>
                            <div style={{display: 'inline-grid',width: '98%'}}>{listItems}</div>
                        </TableCell>
                    </TableRow>*/}
                </TableBody>
            </Table>
            </div>
        </Paper>
    );
}

class SimpleTable  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employer : this.props.empTableInfo,
        }
    }
    onRefresh = () => {
        let employerInput = {employerID: this.props.employerId};
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
                let empData = res.data.employerDetails;
                this.setState({employer : empData});
            });
    };
    render() {
        const empId = this.props.employerId;
        return (
            <div>
                <TableInfo data = {this.state.employer} employerId = {empId} onRefresh={() => {this.onRefresh();}}/>
            </div>
        );
    }
}
export default SimpleTable;
