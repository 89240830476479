import React, {useState, useEffect, useRef, Component, Fragment} from "react";
import './EmployerSearch.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import EmployerInfoTable from "../EmployerInfoTable";
import ApiService from "../../service/ApiService";
import Loader from 'react-loader-spinner';
import useDebounce from './use-debounce';
import TextField from "@material-ui/core/TextField";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',

    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 880,
        backgroundColor: '#ffffff',
    },
    layout: {
        width: "80%",
        marginRight: theme.spacing(2),
		 marginTop:'75px',
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3*2))]: {
            marginTop: theme.spacing(6),
            padding: theme.spacing(3)
        },

        '& label':{
            color:'rgba(0, 0, 0, 0.3)',
            paddingLeft:'20px',

            fontSize:'16px',
            fontStyle:'normal',
        },
        '& label.Mui-focused': {
            color: ' rgb(126, 57, 251)',
            fontSize:'16px',
            paddingTop:'10px',

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border:'none',
            },
            '&:hover fieldset': {
                border:'1px solid',
                borderRightStyle:'hidden',
                borderLeftStyle:'hidden',
                borderBlockStartStyle:'hidden',
                borderRadius:'0px'
            },
            '&.Mui-focused fieldset': {
                border:'2px solid',
                borderRightStyle:'hidden',
                borderLeftStyle:'hidden',
                borderBlockStartStyle:'hidden',
                borderRadius:'0px',
                borderColor: ' rgb(98,0,238)',
            },
        },
    },
    button: {
        float: 'right',
        backgroundColor: '#673ab7',
        marginTop: 30,
        marginLeft: 5,
        '&:hover':{
            backgroundColor: 'rgb(126, 57, 251)',
        },
    },

    searchButton: {
        float: 'right',
        backgroundColor: '#673ab7',
        marginTop: 30,
        marginLeft: 55,
        marginRight: 40,
        '&:hover':{
            backgroundColor: 'rgb(126, 57, 251)',
        },
    },
    text:{
        height:'56px',
        borderRadius:'4px',
        paddingTop:'0px ',
        backgroundColor:'rgba(0, 0, 0, 0.02)',
        '&:hover':{
            backgroundColor:'rgba(0, 0, 0, 0.1)',
            borderRadius:'4px 4px 0px 0px',
        },
    },
}));

function EmployerSearchByName(props) {
    const classes = useStyles();
    // State and setter for search term
    const [searchTerm, setSearchTerm] = useState('');
    // State and setter for search results
    // State for search status (whether there is a pending API request)
    const [isSearching, setIsSearching] = useState(false);
    const [inputId, setInputId] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [matchArray, setMatchArray] = useState([]);
    const [flag, setFlag] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [employerTableInfo, setEmployerTableInfo] = useState("");
    const [employerSOInfo, setEmployerSOInfo] = useState("");
    const [matches, setMatches] = useState([]);

    const debouncedSearchTerm = useDebounce(inputValue, 500);

    // Here's where the API call happens
    // We use useEffect since this is an asynchronous action
    useEffect(
        () => {
            // Make sure we have a value (user has entered something in input)
            if (debouncedSearchTerm) {
                // Set isSearching state
                setIsSearching(true);
                // Fire off our API call
                searchEmployers(debouncedSearchTerm).then(results => {
                    // Set back to false since request finished
                    setIsSearching(false);
                    // Set results state
                    setMatchArray(results);
                    setFlag(true);
                });
            } else {
                setFlag(false);
                setMatchArray([]);
            }


        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    // Select
    const handleSelect = value => {
        setInputValue("");
        setFlag(false);
        searchEmployerDetails(value.employerID);
    };

    function createEmployer() {
        props.props.history.push(
            {
                pathname: '/createEmployer',
                state: {
                    employerName: inputValue
                }
            });
    }

    function searchEmployerDetails(value) {
        setIsLoaded(true);
        setEmployerTableInfo("");
        let employerInput = {employerID: value}
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
                setEmployerTableInfo(res.data);
                setIsLoaded(false);
            });
        ApiService.retriveSODetails(employerInput)
            .then((res) => {
                setEmployerSOInfo(res.data);
                setIsLoaded(false);
            });
    }

    // Handle when click away
    const handleClickAway = () => setMatches([]);

    // Pretty standard UI with search input and results
    return (
        <div className="App">
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <div className="searchBox">
        <Fragment>
            <TextField
                className={classes.text}
                fullWidth
                autoComplete="off"
                label="Search Employer"
                name="autoComplete"
                id ={inputId}
                variant="outlined"
                value={inputValue.toUpperCase()}
                onChange={e => setInputValue(e.target.value)}
                margin="normal" />

            {isSearching && <div style={{textAlign: "center"}} height={50} width={40} >
                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={isSearching} />
            </div>}
            {flag ? (
            <ClickAwayListener onClickAway={handleClickAway}>
            {matchArray.length > 0 ? (
                <MenuList
                    role="listbox"
                    style={{ maxHeight: 300, overflowY: "auto" }}
                >
                    {matchArray.map(match => {
                        const suggest = match.employerName
                            .toUpperCase()
                            .replace(inputValue.toUpperCase(), `<b>${inputValue.toUpperCase()}</b>`);
                        {/*const suggestId = match.employerID
                            .replace(inputId, `<b>${inputId}</b>`);*/}
                        return (
                            <MenuItem
                                role="option"
                                key={match.employerID}
                                onClick={() => handleSelect(match)}
                            >
                                <span dangerouslySetInnerHTML={{ __html: suggest }} />
                            </MenuItem>
                        );
                    })}
                </MenuList>
            ) : (
                <MenuList>
                    <MenuItem>No result found!</MenuItem>
                </MenuList>
            )}
            </ClickAwayListener>
            ) : null}
        </Fragment>
                    </div>

                    {(inputId == 0 || inputId == null) ?
                        <Button
                            variant="contained"
                            color="primary" onClick={createEmployer}
                            className={classes.button}
                            endIcon={<LocationCityIcon />}
                        >
                            CREATE EMPLOYER
                        </Button>
                        :
                        <Button
                            variant="contained"
                            color="primary" onClick={searchEmployerDetails}
                            className={classes.searchButton}
                            endIcon={<LocationCityIcon />}
                        >
                            SEARCH
                        </Button>
                    }
                </Paper>

                <div style={{textAlign: "center"}} height={50} width={40} >
                    <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={isLoaded} />
                </div>


                {(employerTableInfo == '' || employerTableInfo == 'undefined' || employerTableInfo == null) ?
                    <div>
                    </div>
                    :
                    <EmployerInfoTable data={employerTableInfo} employerSOInfo = {employerSOInfo}/>
                }


                    </main>
                    </div>
    );
}

function searchEmployers(search) {
    let employerInput = {employerName: search.toUpperCase()}
    return ApiService.fetchEmployerName(employerInput)
        .then(r => r.data)
        .catch(error => {
            console.error(error);
            return [];
        });
}

class EmployerSearch extends Component{
    constructor(props){
        super(props);
    }

    render() {
        return (
            <EmployerSearchByName props={this.props}/>
        )
    }
}

export default EmployerSearch;