import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Grid from "@material-ui/core/Grid/Grid";
import EditDialog from "./contactsEditDialog";
import ApiService from "../../service/ApiService";
/*import employer from './EmployerTableJson';*/

function TableInfo(props) {
    const classes = style();
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [fullWidth, setFullWidth] = React.useState(true);
    const contacts = props.data;
    let updatedContacts = [];
    const [expanded, setExpanded] = React.useState(false);

    for(let i = 0; i< contacts.length; i++) {
        updatedContacts.push(Object.assign({}, contacts[i]));
        updatedContacts[i].contactPersonFullNameError = '';
        updatedContacts[i].titleCodeError = '';
        updatedContacts[i].birthOnError = '';
        updatedContacts[i].jobTitleError = '';
        updatedContacts[i].emailError = '';
        updatedContacts[i].telephoneError = '';
        if(updatedContacts[i].birthOn !== null && updatedContacts[i].birthOn !== '') {
            let dateParts = updatedContacts[i].birthOn.split("/");
            updatedContacts[i].birthOn = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        }
    }

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const empId = props.employerId;

    const handleClickSetContacts = () => {
        props.onRefresh();
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper className={classes.root}>
            <div style={{marginBottom: '3%'}}>
                <Button className={classes.edit} onClick={handleClickOpen}>
                    Edit<EditRoundedIcon fontSize='small'/>
                </Button>

                <Dialog disableBackdropClick open={open} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <Grid container component="main" >
                        <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                            <EditDialog empContacts={updatedContacts} onClose={() => {handleClose();}} employerId = {empId} onSave={() => {handleClickSetContacts();}}/>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
            <div style={{height:390}} className={classes.contactBox}>
            {contacts.length > 0 ? (
        contacts.map(contact => (
          <ExpansionPanel style={{width:"90%", margin: "0 auto", background: "#f4f4f4", marginBottom: "15px",marginTop: "20px"}} key={contact.empContactId}
                          className={classes.contactBox} expanded={expanded === contact.empContactId} onChange={handleChange(contact.empContactId)}>
             <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel1bh-content"
                 id="panel1bh-header"
             >
            <Typography className={classes.heading} style={{textTransform: 'capitalize',}}>{contact.titleCode} {contact.contactPersonFullName} - {contact.jobTitle}</Typography>
           </ExpansionPanelSummary>
         <ExpansionPanelDetails>
            <Table className={classes.table} aria-label="simple table">

                    <TableBody>
                    <TableRow className={classes.row} key={contact.empContactId}>
                    <TableCell component="th" scope="contact">
                     Contact Name
                    </TableCell>
                    <TableCell align="left" style={{textTransform: 'capitalize'}}>:&nbsp;&nbsp;{contact.titleCode} {contact.contactPersonFullName}</TableCell>
                    </TableRow>
                    <TableRow className={classes.row} key={contact.empContactId}>
                    <TableCell component="th" scope="contact">
                     Contact Job Title
                    </TableCell>
                    <TableCell align="left">:&nbsp;&nbsp;{contact.jobTitle}</TableCell>
                    </TableRow>
                    <TableRow className={classes.row} key={contact.empContactId}>
                    <TableCell component="th" scope="contact">
                     Email
                    </TableCell>
                    <TableCell align="left">:&nbsp;&nbsp;{contact.email}</TableCell>
                    </TableRow>
                    <TableRow className={classes.row} key={contact.empContactId}>
                    <TableCell component="th" scope="contact">
                     Cell Number
                    </TableCell>
                    <TableCell align="left">:&nbsp;&nbsp;{contact.telephone}</TableCell>
                    </TableRow>
                    <TableRow className={classes.row} key={contact.empContactId}>
                    <TableCell component="th" scope="contact">
                     DOB
                    </TableCell>
                    <TableCell align="left">:&nbsp;&nbsp;{contact.birthOn}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
        ))) : (
            <div>
                No contacts available.
            </div>
        )}
        </div>
        </Paper>
    );
}

class contactsTable  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empContacts : this.props.employerContacts.employerContacts,
        }
    }

    onRefresh = () => {
        let employerInput = {employerID: this.props.employerId};
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
                let contactsData = res.data.employerDetails.employerContacts;
                this.setState({empContacts : contactsData});
            });
    };

    render() {
        return (
            <div>
                <TableInfo data = {this.state.empContacts} employerId = {this.props.employerId} onRefresh={() => {this.onRefresh();}}/>
            </div>
        );
    }
}
export default contactsTable;
