import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Grid from "@material-ui/core/Grid/Grid";
import EditDialog from "./benefitsEditDialog";
import ApiService from "../../service/ApiService";
import axios from "axios";
import Loader from "react-loader-spinner";
/*import employer from './EmployerTableJson';*/

function createData(employerInfo, employerValues) {
    return { employerInfo, employerValues};
}

function TableInfo(props) {
    const classes = style();
    const benefits = props.data;
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loaded, setLoaded] = React.useState(true);

    const medicalAidsList = props.medicalAids;
    const pensionFundsList = props.pensionFunds;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const timeout = setTimeout(() => {
        setLoaded(false);
    }, 4000);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickSetEmpBenefits = () => {
        props.onRefresh();
    };

    let medicalAids = benefits.medicalAids.map((d) => <li style={{listStyleType: 'none',marginBottom:'15px',}}
                                                          key={d.medicalSchemeName}>{d.medicalSchemeName}</li>);
    let pensionFunds = benefits.pensionFunds.map((d) => <li style={{listStyleType: 'none',marginBottom:'15px',}}
                                                           key={d.pensionFundName}>{d.pensionFundName}</li>);

    return (
        <Paper className={classes.root}>

            <div style={{height:390}}>
            {loaded === true && <div>
                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={loaded} />
            </div>}
            {loaded !== true &&
            <div>
            <div>
                <Button className={classes.edit} onClick={handleClickOpen}>
                    Edit<EditRoundedIcon fontSize='small'/>
                </Button>

                <Dialog disableBackdropClick open={open} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}}
                        onClose={handleClose} aria-labelledby="form-dialog-title">
                    <Grid container component="main" >
                        <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                            <EditDialog empBenefits={benefits} medicalAidList={medicalAidsList} pensionFundList={pensionFundsList} onClose={() => {handleClose();}} onSave={() => {handleClickSetEmpBenefits();}}/>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>


        <Table  className={classes.table} aria-label="simple table">

                <TableBody >
                     <TableRow className={classes.row}>
                        <TableCell component="th" scope="row" style={{width:'35%'}}>
                             Medical Aid
                         </TableCell>
                        <TableCell align="left">
                            <div style={{display: 'inline-block'}}>:&nbsp;</div>
                            <div style={{display: 'inline-grid',width: '98%'}}>{medicalAids}</div>
                        </TableCell>
                     </TableRow>
                    <TableRow className={classes.row}>
                        <TableCell component="th" scope="row" style={{width:'35%'}}>
                            Pension Fund
                        </TableCell>
                        <TableCell align="left">
                            <div style={{display: 'inline-block'}}>:&nbsp;</div>
                            <div style={{display: 'inline-grid',width: '98%'}}>{pensionFunds}</div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </div>}
        </div>
        </Paper>
    );
}

class SimpleTable  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empBenefits : this.props.employerBenefits,
            medicalAidList: [],
            pensionFundList: [],
        }
    }

    onRefresh = () => {
        let employerInput = {employerID: this.props.employerId};
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
                let benefitsData = res.data.benefits;
                this.setState({empBenefits : benefitsData});
            });
    };

    async componentDidMount() {
        var that = this;
        ApiService.fetchBenefitsList()
            .then(axios.spread(function (medicalAidData, pensionFundData) {
                const medicalAids = medicalAidData.data;
                const pensionFund = pensionFundData.data;
                that.setState({medicalAidList:medicalAids});
                that.setState({pensionFundList:pensionFund});
            }))
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        return (
            <div>
                <TableInfo data = {this.state.empBenefits} medicalAids = {this.state.medicalAidList}
                           pensionFunds = {this.state.pensionFundList} employerId = {this.props.employerId} onRefresh={() => {this.onRefresh();}}/>
            </div>
        );
    }
}
export default SimpleTable;
