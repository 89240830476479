import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import style from './style.js';
import ApiService from "../../service/ApiService";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MomentUtils from "@date-io/moment";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import {
    Formik
} from 'formik';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "@material-ui/pickers";
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import * as Cookies from "js-cookie";
const classes = style;

let userSignOn = "";
const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

if (typeof Cookies.get("auth") !== "undefined") {
    userSignOn = parseJwt(Cookies.get("auth")).sub.employeeNumber;
}

class CaptureEmployer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [{titleCode:'',contactPersonFullName:'', birthOn:null, jobTitle:'', email:'', telephone:'', contactPersonFullNameError:'',
                        titleCodeError:'', birthOnError:'', jobTitleError:'', emailError:'', telephoneError:''}],
            contactEmptyError: false,
            labelWidth: 0,
            titleCodes: [],
            jobTitles: [],
            sectorList: [],
            entityList: [],
            mandatoryErrorFlag: false,
            validationErrorFlag: false,
            isLoaded: false,
            alertOpen: false,
            alertTitle: '',
            alertText: '',
        };
    }


    async componentDidMount() {
        let that = this;
        ApiService.fetchAllListsForCreateEmployer()
       .then(axios.spread(function (sectorData, entityData) {
           let sectors = sectorData.data;
           const entities = entityData.data;
           sectors = sectors.filter(function( obj ) {
               return (obj.sectorId !== -4 && obj.sectorId !== -5);
           });
           that.setState({sectorList:sectors});
           that.setState({entityList:entities});
       }))
            .catch(function (error) {
                console.log(error);
            });

        ApiService.fetchAllListsForCreateEmployerContact()
            .then(axios.spread(function (titlesData, jobTitlesData) {
                const titleCodes = titlesData.data;
                const jobTitles = jobTitlesData.data;
                that.setState({titleCodes:titleCodes});
                that.setState({jobTitles:jobTitles});
            }))
            .catch(function (error) {
                console.log(error);
            });

    }

    handleAlertOpen = () => {
        this.setState({alertOpen: true});
    };

    handleAlertClose = () => {
        if(this.state.alertTitle === 'Success!'){
            this.resetCreateEmployer();
        }
        this.setState({alertOpen: false});
    };

    alertDialog(title, text){
        return (
            <Dialog
                open={this.state.alertOpen}
                onClose={this.handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                style={{marginTop: '-25%'}}
            >
                <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    handleContactChange(i, e) {
        const { name, value } = e.target;
        const errorName = name + 'Error';
        const regexNumber = /^[+0-9\b]+$/;

        let contacts = [...this.state.contacts];
        if( (name === "telephone" && !(regexNumber.test(value)) && value != '')) {
        }
        else {
            contacts[i] = {...contacts[i], [name]: value};
        }

        if(value ==="" || value == null ){
            contacts[i] = {...contacts[i], [errorName]: 'This field is mandatory'};
        }
        else if( name === "email" && !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ) ) {
            contacts[i] = {...contacts[i], [errorName]: 'Please enter valid email address'};
        }
        else if( name === "telephone" && !(/(^0|\+27)[1-9]{1}[0-9]{8}$/.test(value) )) {
            //if((value.toString().startsWith('+27') &&  value.length !== 12) || (value.toString().startsWith('0') &&  value.length !== 10))
            contacts[i] = {...contacts[i], [errorName]: 'Please enter valid contact number'};
        }
        else {
            contacts[i] = {...contacts[i], [errorName]: ''};
        }

        this.setState({ contacts });
    }

    handleTitleChange(i, e) {
        let contacts = [...this.state.contacts];
        contacts[i] = {...contacts[i], titleCode: e.target.value};
        if(e.target.value ==="" || e.target.value == null ){
            contacts[i] = {...contacts[i], titleCodeError: 'Please select title code'};
        }
        else {
            contacts[i] = {...contacts[i], titleCodeError: ''};
        }
        this.setState({ contacts });
    }

    handlejobTitleChange(i, e) {
        let contacts = [...this.state.contacts];
        contacts[i] = {...contacts[i], jobTitle: e.target.value};
        if(e.target.value ==="" || e.target.value == null ){
            contacts[i] = {...contacts[i], jobTitleError: 'Please select job position'};
        }
        else {
            contacts[i] = {...contacts[i], jobTitleError: ''};
        }
        this.setState({ contacts });
    }

    handleDateChange(i, date) {
        let toDate = new Date();
        let contacts = [...this.state.contacts];
        let diff = (toDate.getTime() - new Date(date).getTime())/ 1000;
        diff /= (60 * 60 * 24);
        //let yearDiff = Math.abs(Math.round(diff/365.25));
        let yearDiff = Math.round(diff/365.25);
        if(yearDiff >= 18) {
            contacts[i] = {...contacts[i], birthOnError: ''};
        }
        else if (toDate.getTime() - new Date(date).getTime() <= -0) {
            contacts[i] = {...contacts[i], birthOnError: 'Future date is not allowed'};
        }
        else if (yearDiff < 18 && yearDiff >= 0){
            contacts[i] = {...contacts[i], birthOnError: 'Contact person should be more than 18 years old'};
        }
        contacts[i] = {...contacts[i], birthOn: date};
        this.setState({ contacts });
    }

    addClick(){
        this.setState(prevState => ({
            contacts: [...prevState.contacts, {titleCode:'',contactPersonFullName:'', birthOn:null, jobTitle:'', email:'', telephone:'', contactPersonFullNameError:'',
            titleCodeError:'', birthOnError:'', jobTitleError:'', emailError:'', telephoneError:''}]
    }))
    }

    createUI(){
        return this.state.contacts.map((el, i) => (
            <div key={i}>
            {i != 0 && <hr/>}
            <br/>
        <Grid item xs={12} sm={12}>
            <InputLabel style={classes.contactHeading}> CONTACT {i + 1} </InputLabel>
        </Grid>
        <br/>
        <Grid container spacing={2}>

        <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" style={classes.selectBox}>
                <InputLabel id="titleCode">
                    Contact Title*
                    </InputLabel>
                    <Select
                MenuProps={{ style: classes.menu }}
                value={el.titleCode}
                name="titleCode"
                onChange={this.handleTitleChange.bind(this, i)}
                onBlur={this.handleTitleChange.bind(this, i)}
                labelWidth={90}
                    >
                    { this.state.titleCodes.map(titleCode =>
                        <MenuItem key={titleCode.titleId} value={titleCode.titleCode}>{titleCode.titleDescription}</MenuItem>
                    )}

                    </Select>
              </FormControl>
            <div style={classes.errorText} name="titleCodeError">
                {el.titleCodeError}
            </div>
            </Grid>

            <Grid item xs={12} sm={9}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="contactPersonFullName"
                    value={el.contactPersonFullName ||''}
                    label="Contact Full Name*"
                    name="contactPersonFullName"
                    autoComplete="new-password"
                    onBlur={this.handleContactChange.bind(this, i)}
                    onChange={this.handleContactChange.bind(this, i)}
                    inputProps={{
                        maxLength: 90,
                    }}
            />
            <div style={classes.errorText} name="contactPersonFullNameError">
                {el.contactPersonFullNameError}
            </div>
            </Grid>

        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={2}>
            <InputLabel style={{marginTop: '10%',marginRight: '11%',}}> Date Of Birth </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
                <DatePicker
                    placeholder="DD/MM/YYYY"
                    name="birthOn"
                    id="birthOn"
                    format={"DD/MM/YYYY"}
                    // handle clearing outside => pass plain array if you are not controlling value outside
                    mask={value =>
                    value
                        ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                        : []
                    }
                    value={el.birthOn}
                    onChange={this.handleDateChange.bind(this, i)}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    autoOk={true}
                    clearable
                    onInputChange={e => console.log("Keyboard:", e.target.value)}
            />
            <div style={classes.errorText} name="birthOnError">
                {el.birthOnError}
            </div>
            </Grid>
        </MuiPickersUtilsProvider>

  <Grid item xs={12} sm={6}>

        <FormControl variant="outlined" style={classes.selectBox}>
        <InputLabel id="jobTitle">
            Contact Job Position*
        </InputLabel>
        <Select
        value={el.jobTitle}
        MenuProps={{ style: classes.menu }}
        name = "jobTitle"
        onChange={this.handlejobTitleChange.bind(this, i)}
        onBlur={this.handlejobTitleChange.bind(this, i)}
        labelWidth={150}
            >
            { this.state.jobTitles.map(jobTitle =>
            <MenuItem value={jobTitle.jobTitle} key={jobTitle.jobTitleId}>{jobTitle.jobTitle}</MenuItem>
            )}
            </Select>
            </FormControl>
            <div style={classes.errorText} name="jobTitleError">
                {el.jobTitleError}
            </div>
            </Grid>


        <Grid item xs={12} sm={6}>
            <TextField
        variant="outlined"
        fullWidth
        value={el.email ||''}
        name="email"
        label="Contact Email*"
        id="email"
        autoComplete="new-password"
        onChange={this.handleContactChange.bind(this, i)}
        onBlur={this.handleContactChange.bind(this, i)}
        inputProps={{
            maxLength: 90,
        }}
        />
            <div style={classes.errorText} name="emailError">
                {el.emailError}
            </div>
        </Grid>
        <Grid item xs={12}  sm={6}>
            <TextField
        variant="outlined"
        fullWidth
        id="telephone"
        label="Contact Number*"
        value={el.telephone ||''}
        name="telephone"
        autoComplete="new-password"
        onBlur={this.handleContactChange.bind(this, i)}
        onChange={this.handleContactChange.bind(this, i)}
        />
            <div style={classes.errorText} name="telephoneError">
                {el.telephoneError}
            </div>
        </Grid>

        {i!==0 && <Grid item xs={12}  sm={12}>
            <IconButton aria-label="delete" style={classes.deleteContact} onClick={this.removeClick.bind(this, i)}>
                <DeleteIcon  fontSize="large"/>
            </IconButton>
            </Grid>}
            </Grid>
            <br/>
            </div>
        ))
    }


handleValidation = target => {
    const { name, value } = target;
    const fieldValidationErrors = this.state.formErrors;
    const validity = this.state.formValidity;
    const isEmail = name === "email";
    const isPassword = name === "password";
    const emailTest = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    validity[name] = value.length > 0;
    fieldValidationErrors[name] = validity[name]
        ? ""
        : `${name} is required and cannot be empty`;
    if (validity[name]) {
        if (isEmail) {
            validity[name] = emailTest.test(value);
            fieldValidationErrors[name] = validity[name]
                ? ""
                : `${name} should be a valid email address`;
        }
        if (isPassword) {
            validity[name] = value.length >= 3;
            fieldValidationErrors[name] = validity[name]
                ? ""
                : `${name} should be 3 characters minimum`;
        }
    }
    this.setState({
        formErrors: fieldValidationErrors,
        formValidity: validity
    });
};

removeClick= (i) =>{
    let contacts = [...this.state.contacts];
    let contactEmptyError = this.state.contactEmptyError;
    if(i == 0){
        let title = "ERROR!";
        let message = "You need to add atleast one contact to create a new employer.";
        contactEmptyError = true;
        this.setState({contactEmptyError});
        //this.handleClickOpen(title, message);
    }
    else {
        contactEmptyError = false;
        this.setState({contactEmptyError});
        contacts.splice(i, 1);
        this.setState({contacts});
    }
}

resetCreateEmployer() {
     window.location.reload();
}

render() {
    let employerValue = "";
    employerValue = this.props.location.state === undefined ? "" :this.props.location.state.employerName.trim();
    let isDisabled = employerValue === ""? true:false;

    return (
        <Grid container component="div">
        <Grid item md={10}>
        <div className="App">
        <CssBaseline />
        <main style={classes.layout}>

        <Formik
            initialValues={{employerName:employerValue,regNumber:'',vatNumber:'', taxNumber:'', sectorName:'', entityType:'', webAddress:''}}

            onSubmit={(values,  { setSubmitting }) => {
            let contactList = this.state.contacts;
            for(let i = 0; i< contactList.length; i++) {
                let elem = contactList[i];
                    if(elem.titleCode === "" || elem.contactPersonFullName === "" || elem.jobTitle === "" ||elem.email === "" || elem.telephone === "") {
                        this.setState({mandatoryErrorFlag: true});
                        break;
                    }
                    else if(elem.titleCodeError != "" || elem.contactPersonFullNameError != "" || elem.jobTitleError != "" ||elem.emailError != ""
                        || elem.telephoneError != "" || elem.birthOnError !== "") {
                        this.setState({validationErrorFlag: true});
                        break;
                    }
                    else {
                        this.setState({validationErrorFlag: false});
                        this.setState({mandatoryErrorFlag: false});
                        if(elem.birthOn !== null && elem.birthOn !== "") {
                            let dateElem = elem.birthOn.format('L');
                            contactList[i].birthOn = dateElem;
                        }
                    }
                }

                let valueArr = this.state.contacts.map(function(value){ return value.contactPersonFullName+value.jobTitle });
                let isDuplicate = valueArr.some(function(item, idx){
                    return valueArr.indexOf(item) != idx
                });


                if(this.state.validationErrorFlag === true) {
                    this.setState({alertText: 'Please check that all fields are correct and resave.'})
                    this.setState({alertTitle: 'Error!'});
                    this.handleAlertOpen();
                }
                else if (this.state.mandatoryErrorFlag === true) {
                    this.setState({alertText: 'Please complete all the mandatory fields'});
                    this.setState({alertTitle: 'Error!'});
                    this.handleAlertOpen();
                }
                else if(isDuplicate === true) {
                    this.setState({alertText: 'You have duplicate contacts. Please correct and save.'});
                    this.setState({alertTitle: 'Error!'});
                    this.handleAlertOpen();
                }
                else{
                    console.log("Form is validated! Submitting the form...", values);
                    this.setState({isLoaded:true})
                    let employerAdditionalInfo = {taxNumber: values.taxNumber, entityType:values.entityType};
                    let employer = {employerName: values.employerName.toUpperCase(), companyRegNumber: values.regNumber, vatNumber: values.vatNumber, sectorName:values.sectorName, employerAdditionalInfo,
                        webAddress: values.webAddress.toLowerCase(), employerContacts: contactList, isgetUpEmployer: true, employerUserSignon: userSignOn};
                        ApiService.addEmployer(employer)
                        .then(res => {
                            let result = res;
                            if(result.data.errorStatus === false) {
                                let newBoardRequest = {
                                    employerName: values.employerName.toUpperCase(),
                                    employerDescription: values.sectorName + ', ' + values.entityType,
                                    website: values.webAddress,
                                    key: ApiService.getTrelloKey(),
                                    token: ApiService.getTrelloToken(),
                                    boardsInfo:[ {
                                                    boardName: values.employerName.toUpperCase(),
                                                    boardMembers: [ {
                                                            email:'met_test1@metropolitan.co.za',
                                                            name:'Susilla 1'
                                                        },
                                                        {
                                                            email:'met_test3@metropolitan.co.za',
                                                            name:'Susilla 3'
                                                        }
                                                    ]
                                                } ]
                                }

                                ApiService.addFlowEmployerBoard(newBoardRequest)
                                    .then(flowRes => {
                                        let flowResult = flowRes;
                                        this.setState({isLoaded:false});
                                        if(flowResult.data.errorStatus === false) {
                                            this.setState({alertText: result.data.responseMessage})
                                            this.setState({alertTitle: 'Success!'});
                                            this.handleAlertOpen();
                                        }
                                        else {
                                            this.setState({alertText: 'Employer created successfully and there was an error happened while creating the board on FLOW'})
                                            this.setState({alertTitle: 'Success!'});
                                            this.handleAlertOpen();
                                        }
                                    });
                            }
                            else {
                                this.setState({isLoaded:false})
                                this.setState({alertText: result.data.responseMessage})
                                this.setState({alertTitle: 'Error!'});
                                this.handleAlertOpen();
                            }
                        });
                    setSubmitting(false)
                }
            }}

            validationSchema={Yup.object().shape({
                webAddress: Yup.string()
                    .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, "Please enter valid web address")
                    .required("Please enter web address")
                    .max(250, "Web Address is too long - should be 250 characters maximum."),
                employerName: Yup.string()
                    .required("Please enter Employer Name")
                    .min(3, "Employer Name is too short - should be 3 characters minimum.")
                    .max(250, "Employer Name is too long - should be 250 characters maximum."),
                regNumber: Yup.string()
                    .required("Please enter Employer Registration Number")
                    .min(3, "Employer Registration Number is too short - should be 3 characters minimum.")
                    .max(35, "Employer Registration Number is too long - should be 35 characters maximum."),
                vatNumber: Yup.string()
                    .max(45, "VAT Number is too long - should be 45 characters maximum."),
                taxNumber: Yup.string()
                    .max(35, "VAT Number is too long - should be 35 characters maximum."),
                sectorName: Yup.string()
                    .required("Please select Sector"),
                entityType: Yup.string()
                    .required("Please select Entity Type"),
            })}
            >
        {props => {
            const {
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setSubmitting
            } = props;
            return (
             <form noValidate onSubmit={handleSubmit} style={classes.form} id="CaptureEmployerForm">

                <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                <Box  mb={3}>
                    Create New Employer
                </Box>
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                            autoComplete="new-password"
                            name="employerName"
                            variant="outlined"
                            id="employerName"
                            fullWidth
                            disabled = {!isDisabled}
                            onChange={handleChange}
                            value={values.employerName.toUpperCase()}
                            onBlur={handleBlur}
                            helperText={<Typography
                                style={{color:'red',}}
                                 >
                                    {(errors.employerName && touched.employerName) && errors.employerName}
                                </Typography>
                             }
                            name="employerName"
                            label="Employer Name*"
                           />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.regNumber}
                            helperText={<Typography
                                style={{color:'red',}}
                                 >
                                    {(errors.regNumber && touched.regNumber) && errors.regNumber}
                                </Typography>
                             }
                            fullWidth
                            id="regNumber"
                            label="Company Registration Number*"
                            name="regNumber"
                            autoComplete="new-password"
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.vatNumber}
                        fullWidth
                        name="vatNumber"
                        label="VAT Number"
                        id="vatNumber"
                        autoComplete="new-password"
                        helperText={<Typography
                            style={{color:'red',}}
                        >
                            {(errors.vatNumber && touched.vatNumber) && errors.vatNumber}
                        </Typography>
                        }
                        />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.taxNumber}
                        fullWidth
                        name="taxNumber"
                        label="TAX Number"
                        id="taxNumber"
                        autoComplete="new-password"
                        helperText={<Typography
                            style={{color:'red',}}
                        >
                            {(errors.taxNumber && touched.taxNumber) && errors.taxNumber}
                        </Typography>
                        }
                        />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <FormControl style={classes.selectBox}>
                                <TextField
                                  type="text"
                                  name="sectorName"
                                  label="Sector*"
                                  select
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    value={values.sectorName}
                                    helperText={<Typography
                                        style={{color:'red',}}
                                        >
                                        {(errors.sectorName && touched.sectorName) && errors.sectorName}
                                     </Typography>
                                    }
                                  margin="normal"
                                >
                                  {this.state.sectorList.map(sector => (
                                    <MenuItem key={sector.sectorName} value={sector.sectorName}>
                                      {sector.sectorName}
                                    </MenuItem>
                                  ))}
                                </TextField>
                        </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={6}>

                            <FormControl style={classes.selectBox}>
                                <TextField
                                  type="text"
                                  name="entityType"
                                  label="Entity Type*"
                                  select
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    value={values.entityType}
                                    helperText={<Typography
                                        style={{color:'red',}}
                                        >
                                        {(errors.entityType && touched.entityType) && errors.entityType}
                                     </Typography>
                                    }
                                  margin="normal"
                                >
                                  {this.state.entityList.map(option => (
                                    <MenuItem key={option.entityType} value={option.entityType}>
                                      {option.entityType}
                                    </MenuItem>
                                  ))}
                                </TextField>
                        </FormControl>
                        </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.webAddress.toLowerCase()}
                            helperText={<Typography
                                style={{color:'red',}}
                                 >
                                    {(errors.webAddress && touched.webAddress) && errors.webAddress}
                                </Typography>
                             }
                            fullWidth
                            id="webAddress"
                            label="Web Address*"
                            name="webAddress"
                            autoComplete="new-password"
                        />
                        </Grid>

                        </Grid>
                        <br/>
                        <br/>
                        <hr/>
                    {this.alertDialog()}
                    {this.createUI()}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick = {this.addClick.bind(this)}
                        style={classes.newContact}
                        >
                            Add New Contact
                    </Button>

                    <div>
                    <br/>
                    <br/>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={classes.submit}
                        disabled={!setSubmitting}
                    >
                        SUBMIT
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={classes.submit}
                            onClick = {this.resetCreateEmployer}
                        >
                            RESET
                        </Button>

                        </div>
                         <div style={{textAlign: "center"}} height={50} width={40} >
                             <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={this.state.isLoaded} />
                         </div>
                    </form>
                    );
                }}
            </Formik>
        {/* END OF FORMIK */}
        </main>
        </div>
        </Grid>
    </Grid>
    )}
}
export default CaptureEmployer;
