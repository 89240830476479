import React, { Component } from 'react';
import './App.css';
import LoginComponent from "./components/Login/LoginPage";
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import * as Cookie from "js-cookie";
import './App.css';
import Employer from "./components/Employer";
import requireAuth from "./components/Login/AuthenticatedComponent";
import {createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    /*typography: {
        fontFamily: [
            'Gilroy-Regular',
        ].join(','),
    },
    palette:  {
        background: {
            default: fade("#9e9e9e", 0.07)
        }
    }*/
});


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {loginStatus: true};
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

    }

    state = {
        auth: true,
    };

    componentDidMount() {
        if (!Cookie.get('auth')) {
            this.setState({ auth: false });
        }
    }

    render() {
        return (

            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <BrowserRouter>
                  <Switch>
                    <Route exact path="/login" component={LoginComponent} />
                    <Route exact path="/" component={requireAuth(Employer)} />
                    {!this.state.auth &&  <Redirect push to="/login"/> }
                  </Switch>
                </BrowserRouter>
              </Provider>
            </ThemeProvider>
    );
    }
}

export default App;
