import {makeStyles} from "@material-ui/core/styles/index";

const drawerWidth = '16%';

const style = makeStyles(theme => ({
    root: {
        display: 'flex',
      
    },
    appBar: {
         zIndex: theme.zIndex.drawer ,
         
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
           
        }),
          backgroundColor:'rgb(240, 237, 237)' ,
            boxShadow:'none',
    },
  
    appBarShift: {
        marginLeft: drawerWidth,
   
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
           
       }),
        
         
    },
    menuButton: {
        color:'white',
        marginRight: 36,
        marginTop:10,
    },
    menuExpand: {
        color:'white',
        margin:'auto',
    },
    menuHide: {
        color:'white',
        marginTop:5

    },
    hide: {
        display: 'none',
       
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
         
    },
    imgtext:{
         marginLeft:'55px'
    },
    nametext:{
        textAlign: 'center',
    },
    img:{
        margin: 'auto',
    },
    imgShrink: {

    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
                   backgroundColor:'rgb(103, 58, 183)',
                    color:'rgb(257, 257, 257)'

    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
           backgroundColor:'rgb(103, 58, 183)',
          
        overflowX: 'hidden',
        width: '5%',
    },
    toolbar: {
        color:'rgb(257,257,257)',
        backgroundColor:'rgb(103,58,183)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 'auto',
    },
    list:{
    color:'rgb(257,257,257)',
    },
    dividerLine:{
        '&.MuiDivider-root':  {
            backgroundColor : 'rgba(255, 255, 255, 0.12)',
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
       
         
    },
    employerIcon: {
        color: '#fff',
    }
}));

export default style;
