import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
/*import employer from './EmployerTableJson';*/

function createData(employerInfo, employerValues, empId) {
    return { employerInfo, employerValues, empId};
}

function TableInfo(props) {
    const classes = style();
    const employer = props.data;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const rows = [
        createData('Physical address:', employer.employerName, 21),
        createData('Postal address:', employer.employerName, 21),
    ]

    return (
        <Paper className={classes.root}>
            <div style={{height:390}}>
    {/*<Button className={classes.edit} onClick={handleClickOpen}>
                Edit<EditRoundedIcon fontSize='small'/>
            </Button>*/}
            <Table  className={classes.table} aria-label="simple table">
                <TableHead >
                    <TableRow >
                        <TableCell ></TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows.map(row => (
     <TableRow className={classes.row} key={row.empId}>
         <TableCell component="th" scope="row">
             {row.employerInfo}
         </TableCell>
         <TableCell align="left">{row.employerValues}</TableCell>
     </TableRow>
 ))}
                </TableBody>
            </Table>
        </div>
        </Paper>
    );
}

class SimpleTable  extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const empBranches = this.props.employerBranches;
        return (
            <div>
                <TableInfo data = {empBranches} />
            </div>
        );
    }
}
export default SimpleTable;
