import React from 'react';
import Sidebar from "../Sidebar";
import Grid from '@material-ui/core/Grid';

function employer(props) {

    return (
            <Grid container component="main">
                        <Sidebar props = {props}/>
                </Grid>
    );
}

export default employer;


