import React, {Component, useState} from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import style from '../CaptureEmployer/style.js';
import basicStyle from './style.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Typography from "@material-ui/core/Typography/Typography";
import Loader from "react-loader-spinner";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ApiService from "../../service/ApiService";
import Box from '@material-ui/core/Box';
import * as Cookies from "js-cookie";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

let userSignOn = "";
const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


// And now we can use these
function AddressEditDialogComponent (props) {
    const classes = style;
    const data = props.data;
    const boxClasses = basicStyle();
    const [isLoaded, setIsLoaded] = useState(false);
    const provinces = props.provinceList;
    const addressTypeCode = props.addressTypeCode;
    const employerId = props.employerId;
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertText, setAlertText] = React.useState('');

    let addressTypeLabel = "";
    if(addressTypeCode === 'P')
        addressTypeLabel = "Postal";
    else if (addressTypeCode === 'W')
        addressTypeLabel = "Physical";
    const handleClose = () => {
        props.onClose();
    };

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    function alertDialog (title, text){
        return (
            <Dialog
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                style={{marginTop: '-25%'}}
            >
                <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleSave = () => {
        props.onSave();
    }

    return (

        <Grid container component="div">
            <Grid item md={10} style={{marginBottom: '25px', margin: '0 auto',marginTop: '4%'}}>
                <div className="App">
                    <CssBaseline />
                    <main style={classes.popUpLayout}>

                        <Formik enableReinitialize
                            initialValues={{addressTypeDesc: addressTypeLabel, boxLineAddressLineOne : data.boxLineAddressLineOne, boxLineAddressLineTwo : data.boxLineAddressLineTwo, boxLineAddressLineThree: data.boxLineAddressLineThree,
                                buildingNumber: data.buildingNumber, floorNumber: data.floorNumber, buildingName: data.buildingName, streetNumber: data.streetNumber,
                                streetName: data.streetName, cornerOf: data.cornerOf, suburb: data.suburb, town: data.town, provinceCode: data.provinceCode, postalCode: data.postalCode}}

                            onSubmit={(values,  { setSubmitting }) => {
                                setIsLoaded(true);
                                let address = [{addressID: data.addressID, addressTypeCode: addressTypeCode, longitudeCoordinates : 'long', latitudeCoordinates : 'lat',
                                    boxLineAddressLineOne : values.boxLineAddressLineOne, boxLineAddressLineTwo : values.boxLineAddressLineTwo, boxLineAddressLineThree: values.boxLineAddressLineThree,
                                    buildingNumber: values.buildingNumber, floorNumber: values.floorNumber, buildingName: values.buildingName, streetNumber: values.streetNumber,
                                    streetName: values.streetName, cornerOf: values.cornerOf, suburb: values.suburb, town: values.town, provinceName: values.provinceName, postalCode: values.postalCode,
                                    provinceCode: values.provinceCode, userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber, employerID: employerId}];

                                    console.log("Form is validated! Submitting the form...", address);

                                    ApiService.updateEmployerAddress(address)
                                        .then(res => {
                                            var result = res;
                                            console.log("response : " , result);
                                            setIsLoaded(false);
                                            if(result.data.errorStatus === false) {
                                                handleSave();
                                                setAlertText(result.data.responseMessage);
                                                setAlertTitle('Success!');
                                                handleAlertOpen();
                                            }
                                            else {
                                                setAlertTitle('Error!');
                                                setAlertText(result.data.responseMessage);
                                                handleAlertOpen();
                                            }
                                        });
                                setSubmitting(false);
                            }}

                            validationSchema={Yup.object().shape({
                                boxLineAddressLineOne: Yup.string()
                                    .max(50, "Box address one is too long - should be 50 characters maximum."),

                                boxLineAddressLineTwo : Yup.string()
                                    .max(50, "Box address two is too long - should be 50 characters maximum."),
                                boxLineAddressLineThree: Yup.string()
                                    .max(50, "Box address three is too long - should be 50 characters maximum."),
                                buildingNumber: Yup.string()
                                    .max(20, "Building Number is too long - should be 20 characters maximum."),
                                floorNumber: Yup.string()
                                    .max(20, "Floor Number is too long - should be 20 characters maximum."),
                                buildingName: Yup.string()
                                    .max(100, "Building Name is too long - should be 100 characters maximum."),
                                streetNumber: Yup.string()
                                    .max(20, "Street Number is too long - should be 20 characters maximum."),
                                streetName: Yup.string()
                                    .max(100, "Street Name is too long - should be 100 characters maximum."),
                                cornerOf: Yup.string()
                                    .max(150, "Corner Of is too long - should be 100 characters maximum."),

                                suburb: Yup.string()
                                    .max(100, "Suburb Name is too long - should be 100 characters maximum.")
                                    .required("Please enter Suburb"),
                                town: Yup.string()
                                    .max(100, "Town Name is too long - should be 100 characters maximum.")
                                    .required("Please enter Town"),
                                postalCode: Yup.string()
                                    .max(4, "Postal Code is too long - should be 4 characters maximum.")
                                    .required("Please enter Postal code"),
                                provinceCode: Yup.string()
                                    .required("Please select Province Name"),

                            })}
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    setSubmitting
                                } = props;
                                return (
                                    <form noValidate onSubmit={handleSubmit} id="EditBasicInfoForm">

                                        <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                                                      <Box  mb={3}>
                                                          Edit Employer Address
                                                      </Box>
                                        </Typography>
                                        {alertDialog()}
                                        <Grid container spacing={2} style={{textTransform: 'capitalize'}}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="addressTypeDesc"
                                                    variant="outlined"
                                                    id="addressTypeDesc"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    disabled
                                                    value={values.addressTypeDesc}
                                                    label="Address Type*"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="boxLineAddressLineOne"
                                                    variant="outlined"
                                                    id="boxLineAddressLineOne"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.boxLineAddressLineOne}
                                                    label="Box Address Line 1"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.boxLineAddressLineOne && touched.boxLineAddressLineOne) && errors.boxLineAddressLineOne}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="boxLineAddressLineTwo"
                                                    variant="outlined"
                                                    id="boxLineAddressLineTwo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.boxLineAddressLineTwo}
                                                    label="Box Address Line 2"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.boxLineAddressLineTwo && touched.boxLineAddressLineTwo) && errors.boxLineAddressLineTwo}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="boxLineAddressLineThree"
                                                    variant="outlined"
                                                    id="boxLineAddressLineThree"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.boxLineAddressLineThree}
                                                    label="Box Address Line 3"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.boxLineAddressLineThree && touched.boxLineAddressLineThree) && errors.boxLineAddressLineThree}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="buildingNumber"
                                                    variant="outlined"
                                                    id="buildingNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.buildingNumber}
                                                    label="Building Number"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.buildingNumber && touched.buildingNumber) && errors.buildingNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="floorNumber"
                                                    variant="outlined"
                                                    id="floorNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.floorNumber}
                                                    label="Floor Number"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.floorNumber && touched.floorNumber) && errors.floorNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="buildingName"
                                                    variant="outlined"
                                                    id="buildingName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.buildingName}
                                                    label="Building Name"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.buildingName && touched.buildingName) && errors.buildingName}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="streetNumber"
                                                    variant="outlined"
                                                    id="streetNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.streetNumber}
                                                    label="Street Number"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.streetNumber && touched.streetNumber) && errors.streetNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="streetName"
                                                    variant="outlined"
                                                    id="streetName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.streetName}
                                                    label="Street Name"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.streetName && touched.streetName) && errors.streetName}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="cornerOf"
                                                    variant="outlined"
                                                    id="cornerOf"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.cornerOf}
                                                    label="Corner Of"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.cornerOf && touched.cornerOf) && errors.cornerOf}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="suburb"
                                                    variant="outlined"
                                                    id="suburb"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    value={values.suburb}
                                                    label="Suburb*"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.suburb && touched.suburb) && errors.suburb}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="town"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    id="town"
                                                    fullWidth
                                                    value={values.town}
                                                    label="Town*"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.town && touched.town) && errors.town}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl style={classes.selectBox}>
                                                    <TextField
                                                        type="text"
                                                        name="provinceCode"
                                                        label="Province Name*"
                                                        select
                                                        id="provinceCode"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        value={values.provinceCode}
                                                        helperText={<Typography
                                                            style={{color:'red',}}
                                                        >
                                                            {(errors.provinceCode && touched.provinceCode) && errors.provinceCode}
                                                        </Typography>
                                                        }
                                                    >

                                                        {provinces.map(option => (
                                                            <MenuItem key={option.rsaProvinceCode} value={option.rsaProvinceCode}>
                                                                {option.rsaProvinceName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="postalCode"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    id="postalCode"
                                                    fullWidth
                                                    value={values.postalCode}
                                                    label="postal Code*"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.postalCode && touched.postalCode) && errors.postalCode}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                        </Grid>
                                        <br/>

                                        <div style={{marginBottom:'2%'}}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={classes.editSubmit}
                                                disabled={!setSubmitting}
                                            >
                                                SAVE
                                            </Button>

                                            <Button
                                                onClick = {handleClose}
                                                variant="contained"
                                                color="primary"
                                                style={classes.submit}
                                            >
                                                CANCEL
                                            </Button>

                                        </div>
                                        <div style={{textAlign: "center"}} height={50} width={40} >
                                            <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={isLoaded} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                        {/* END OF FORMIK */}
                    </main>
                </div>
            </Grid>
        </Grid>
);
};

class AddressEditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rsaProvinceList : [],
        };
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleSave = () => {
        this.props.onSave();
    };

    async componentDidMount() {
        var that = this;
        ApiService.fetchRSAProvinceList()
            .then((res) => {
                console.log("Inside")
                that.setState({rsaProvinceList: res.data});
            });

    }

    render() {
        const addressDetails = this.props.empAddress;
        const addressTypeCode = this.props.addressTypeCode;
        const employerId = this.props.employerId;
        return (
            <div>
                <AddressEditDialogComponent data = {addressDetails} provinceList = {this.state.rsaProvinceList}
                                            employerId = {employerId} onClose={() => {this.handleClose();}} onSave={() => {this.handleSave();}} addressTypeCode={addressTypeCode}/>
            </div>
        );
    }
}
export default AddressEditDialog;
