let API_URL;
let MET_RETAIL_API_URL;
let MS365_API_URL;
const API_CONTEXT_ROOT = 'employerportalbackend-api';

const host = window && window.location && window.location.hostname;
if(host === 'localhost'){
    API_URL = 'https://localhost:9080/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://internal-retail-tst.metropolitan.co.za/auth/login';
}else if (host === 'www.dev-markets.metgetup.co.za'){
    API_URL = 'https://api-external.metropolitan.co.za/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://internal-retail-tst.metropolitan.co.za/auth/login';
}else if (host === 'www.pre-markets.metgetup.co.za'){
    API_URL = 'https://api-external.metropolitan.co.za/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://internal-retail-pre.metropolitan.co.za/auth/login';
}else if (host === 'www.markets.metgetup.co.za'){
    API_URL = 'https://api-internal.metropolitan.co.za/employerportalbackend';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://internal-retail.metropolitan.co.za/auth/login';
}
else {
    API_URL = 'https://api-external.metropolitan.co.za/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://internal-retail-tst.metropolitan.co.za/auth/login';
}


export const API_CONFIG = API_URL.concat(API_CONTEXT_ROOT);
export const MS_365_API_CONFIG = MS365_API_URL.concat(API_CONTEXT_ROOT);
export const MET_RETAIL_API_CONFIG = MET_RETAIL_API_URL;
