import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import style from './style.js';
import EmployerBasicInfo from '../EmployerBasicInfo';
import EmployerSOInfo from '../EmployerSOInfo';
import EmployerAddress from '../EmployerAddress';
import EmployerContacts from '../EmployerContacts';
import EmployerBenefits from '../EmployerBenefits';
import EmployerDocuments from '../EmployerDocuments';
import EmployerEvents from '../EmployerEvents';
import EmployerLifeCoaches from '../EmployerLifeCoaches';
import EmployerBranches from '../EmployerBranches';
import EmployerLocations from '../EmployerLocation';
import EmployerSocialMedia from '../EmployerSocialMedia';
import EmployerHours from '../EmployerOperatingHours'
import LocationCityIcon from '@material-ui/icons/LocationCity';

function TabPanel(props) {
    const {value, index, empTableInfo, employerSOInfo, employerAddress, employerId, employerContacts,
            employerBenefits, employerBranches, employerDocuments, employerEvents, employerLifeCoach, employerLocations, employerSocialMedia, employerHours} = props;
    if (index === 0 && value === index){
        return (
            <EmployerBasicInfo empTableInfo = {empTableInfo} employerId = {employerId}/>
        );
    }
    else if (index === 1 && value === index){
        return (
            <EmployerSOInfo employerSOInfo = {employerSOInfo}/>
        );
    }
    else if (index === 2 && value === index){
        return (
            <EmployerAddress employerAddress = {employerAddress} employerId = {employerId}/>
    );
    }
    else if (index === 3 && value === index){
        return (
            <EmployerContacts employerContacts = {employerContacts} employerId = {employerId}/>
    );
    }
    else if (index === 4 && value === index){
        return (
            <EmployerBenefits employerBenefits = {employerBenefits} employerId = {employerId}/>
    );
    }
    else if (index === 5 && value === index){
        return (
            <EmployerSocialMedia employerSocialMedia = {employerSocialMedia} employerId = {employerId}/>
    );
    }
    else if (index === 6 && value === index){
        return (
            <EmployerHours employerHours = {employerHours} employerId = {employerId}/>
    );
    }
    else if (index === 7 && value === index){
        return (
            <EmployerEvents employerEvents = {employerEvents}/>
    );
    }
    else if (index === 8 && value === index){
        return (
            <EmployerLifeCoaches employerLifeCoach = {employerLifeCoach}/>
    );
    }
    else if (index === 9 && value === index){
        return (
            <EmployerLocations employerLocations = {employerLocations}/>
    );
    }
    else if (index === 10 && value === index){
        return (
            <EmployerBranches employerBranches = {employerBranches}/>
    );
    }

    else if (index === 11 && value === index){
        return (
            <EmployerDocuments employerDocuments = {employerDocuments}/>
        );
    }

    /*if (index === 2 && value === index){
        return (
            <EmployerLocation />
    );
    }
    if (index === 3 && value === index){
        return (
            <EmployerEngagements />
    );
    }*/
    else{
        return (
           <div>
           </div>
        );
    }
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EmployerInfoTable(props) {
    const classes = style();
    const [value, setValue] = React.useState(0);
    const empDetails = props.data.employerDetails;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(empDetails === "undefined'"|| empDetails === "") {
        return (
            <div>
            </div>
        );
    }
    else{
        return (
            <Grid container component="div" className={classes.root}>
                <Grid item md={10} className={classes.employerTable}>
                    <Typography component="h1" variant="h5" align="left" className={classes.employerHeading}>
                        <LocationCityIcon/>
                        {empDetails.employerName}
                    </Typography>
                    <Typography component="h2" variant="subtitle2" align="left">
                        <Box fontFamily="fontFamily" mt={1} mb={2} ml={3}>
                            {empDetails.employerStatus}
                        </Box>
                    </Typography>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} className={classes.tableTabs}
                              aria-label="simple tabs example"
                                variant="scrollable"
                                scrollButtons="auto">
                            <Tab label="BASIC INFO" {...a11yProps(0)} />
                                {/*
                                <React.Fragment>
                                    <span className="circle">6</span>
                                    STOP ORDER AGENCY
                                </React.Fragment>*/}
                            <Tab label="STOP ORDER AGENCIES" {...a11yProps(1)} />
                            <Tab label="ADDRESS" {...a11yProps(2)} />
                            <Tab label="CONTACTS" {...a11yProps(3)} />
                            <Tab label="BENEFITS" {...a11yProps(4)} />
                            <Tab label="SOCIAL MEDIA" {...a11yProps(5)} />
                            <Tab label="OPERATING HOURS" {...a11yProps(6)} />
                            {/*<Tab label="BRANCHES" {...a11yProps(5)} />
                            <Tab label="DOCUMENTS" {...a11yProps(6)} />
                            <Tab label="EVENTS" {...a11yProps(7)} />
                            <Tab label="LIFE COACH" {...a11yProps(8)} />
                            <Tab label="LOCATIONS" {...a11yProps(9)} />*/}
                            {/* <Tab label="MAP" {...a11yProps(2)} />
                            <Tab label="ENGAGEMENTS" {...a11yProps(2)} />*/}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} empTableInfo={props.data.employerDetails} employerId = {props.data.employerDetails.employerID}>
                    </TabPanel>
                    <TabPanel value={value} index={1} employerSOInfo = {props.employerSOInfo}>
                    </TabPanel>
                    <TabPanel value={value} index={2} employerAddress={props.data.employerAddress} employerId = {props.data.employerDetails.employerID}>
                    </TabPanel>
                    <TabPanel value={value} index={3} employerContacts={props.data.employerDetails}  employerId = {props.data.employerDetails.employerID}>
                    </TabPanel>
                    <TabPanel value={value} index={4} employerBenefits={props.data.benefits} employerId = {props.data.employerDetails.employerID}>
                    </TabPanel>
                    <TabPanel value={value} index={5} employerSocialMedia={props.data.socialMediaDetails} employerId = {props.data.employerDetails.employerID}>
                    </TabPanel>
                    <TabPanel value={value} index={6} employerHours={props.data.employerDetails} employerId = {props.data.employerDetails.employerID}>
                    </TabPanel>
                    <TabPanel value={value} index={7} employerDocuments={props.data.employerDetails}>
                    </TabPanel>
                    <TabPanel value={value} index={8} employerEvents={props.data.employerDetails}>
                    </TabPanel>
                    <TabPanel value={value} index={9} employerLifeCoach={props.data.employerDetails}>
                    </TabPanel>
                    <TabPanel value={value} index={10} employerLocations={props.data.employerDetails}>
                    </TabPanel>
                    <TabPanel value={value} index={11} employerBranches={props.data.employerDetails}>
                    </TabPanel>

                </Grid>
            </Grid>
        );
    }
}

export default EmployerInfoTable;