import React, {Component, useState} from 'react';
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import style from '../CaptureEmployer/style.js';
import basicStyle from './style.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Typography from "@material-ui/core/Typography/Typography";
import Loader from "react-loader-spinner";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ApiService from "../../service/ApiService";
import axios from "axios";
import Box from '@material-ui/core/Box';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Constants from "../../utils/Constants";
import * as Cookies from "js-cookie";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

let userSignOn = "";
const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


// And now we can use these
function BasicInfoEditDialogComponent (props) {
    const classes = style;
    const entityList = props.entityList;
    const sectorList = props.sectorList;
    const opStatusList = props.opStatusList;
    const data = props.data;
    const monthList = Constants.fetchMonths();
    const adlData = data.employerAdditionalInfo;
    const boxClasses = basicStyle();
    const [isLoaded, setIsLoaded] = useState(false);
    const [employerGroup, setEmployerGroup] = React.useState('false');
    const [multinationalCompany, setMultinationalCompany] = React.useState('false');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertText, setAlertText] = React.useState('');

    const handleClose = () => {
        props.onClose();
    };

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    function alertDialog (title, text){
        return (
            <Dialog
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                style={{marginTop: '-25%'}}
            >
                <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleSave = () => {
        props.onSave();
    }

    React.useEffect(() => {
        if(adlData.employerGroup === true)
            setEmployerGroup("true");
        else
            setEmployerGroup("false");
        if(adlData.employerGroup === true)
            setMultinationalCompany("true");
        else
            setMultinationalCompany("false");
    }, []);

    const handleCheckChange = event => {
        let name = event.currentTarget.name;
        if(name === "employerGroup")
            setEmployerGroup(event.target.value);
        else if(name === "multinationalCompany")
            setMultinationalCompany(event.target.value);
    };

    return (

        <Grid container component="div">
            <Grid item md={10} style={{marginBottom: '25px', margin: '0 auto',marginTop: '4%'}}>
                <div className="App">
                    <CssBaseline />
                    <main style={classes.popUpLayout}>

                        <Formik enableReinitialize
                            initialValues={{employerID: data.employerID, companyRegNumber: data.companyRegNumber, sectorName: data.sectorName, employerGroup: adlData.employerGroup, entityType: adlData.entityType,
                                multinationalCompany: adlData.multinationalCompany,operationalStatus: adlData.operationalStatus,
                                vatNumber: data.vatNumber, taxNumber: adlData.taxNumber, noEmployed: adlData.noEmployed, financialYearEndMonth : adlData.financialYearEndMonth, employerNotes: adlData.employerNotes,
                                natureOfBusiness: adlData.natureOfBusiness, telephoneNumber: adlData.telephoneNumber, faxNumber: adlData.faxNumber, webAddress: data.webAddress, emailAddress: adlData.emailAddress
                            }}

                            onSubmit={(values,  { setSubmitting }) => {
                                console.log("Form is validated! Submitting the form...", values);
                                setIsLoaded(true);
                                let employerAdditionalInfo = { employerAddInfoID: data.employerAdditionalInfo.employerAddInfoID, employerID: data.employerID,
                                    entityType:values.entityType, employerGroup: employerGroup === "true"? true:false,multinationalCompany: multinationalCompany  === "true"? true:false,
                                    operationalStatus: values.operationalStatus, noEmployed: values.noEmployed, financialYearEndMonth : values.financialYearEndMonth.toUpperCase(),
                                    employerNotes: values.employerNotes, natureOfBusiness: values.natureOfBusiness, telephoneNumber: values.telephoneNumber, operationalStatusId: data.employerAdditionalInfo.operationalStatusId,
                                    faxNumber: values.faxNumber, taxNumber: values.taxNumber, emailAddress: values.emailAddress, userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber};
                                let employer = {employerID: data.employerID,
                                    companyRegNumber: values.companyRegNumber,
                                    sectorName:values.sectorName,
                                    vatNumber: values.vatNumber,
                                    employerAdditionalInfo: employerAdditionalInfo,
                                    webAddress: values.webAddress.toLowerCase(),
                                    employerContacts: [],
                                    employerUserSignon: parseJwt(Cookies.get("auth")).sub.employeeNumber};

                                ApiService.updateEmployerBasicInfo(employer)
                                    .then(res => {
                                        var result = res;
                                        console.log("response : " , result);
                                        setIsLoaded(false);
                                        if(result.data.errorStatus === false) {
                                            setAlertText(result.data.responseMessage);
                                            setAlertTitle('Success!');
                                            handleAlertOpen();
                                            handleSave();
                                        }
                                        else {
                                            setAlertTitle('Error!');
                                            setAlertText(result.data.responseMessage);
                                            handleAlertOpen();
                                        }
                                    });
                                setSubmitting(false);
                            }}

                            validationSchema={Yup.object().shape({
                                vatNumber: Yup.string()
                                    .max(45, "VAT Number is too long - should be 45 characters maximum."),
                                taxNumber: Yup.string()
                                    .max(35, "TAX Number is too long - should be 35 characters maximum."),
                                companyRegNumber: Yup.string()
                                    .max(35, "Company Registration Number is too long - should be 35 characterss maximum."),
                                sectorName: Yup.string()
                                    .required("Please select Sector"),
                                entityType: Yup.string()
                                    .required("Please select Entity Type"),
                                operationalStatus: Yup.string()
                                    .required("Please select Operational Status"),
                                noEmployed: Yup.string()
                                    .typeError('Please enter valid number employed')
                                    .matches(/^[1-9]\d*$/, "Please enter valid Number Employed")
                                    .required("Please enter Number Employed"),
                                financialYearEndMonth: Yup.string()
                                    .required("Please select financial year end month"),
                                employerNotes: Yup.string()
                                    .max(1000, "Employer notes is too long - should be 1000 characters maximum.")
                                    .required("Please enter employer notes"),
                                natureOfBusiness: Yup.string()
                                    .max(250, "Nature of Business is too long - should be 250 characters maximum.")
                                    .required("Please enter nature of business"),
                                telephoneNumber: Yup.string()
                                    .matches(/(^0|\+27)[1-9]{1}[0-9]{8}$/, "Please enter valid telephone number")
                                    .required("Please enter employer telephone number"),
                                faxNumber: Yup.string()
                                    //.matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, "Please enter valid fax number")
                                    .matches(/(^0|\+27)[1-9]{1}[0-9]{8}$/, "Please enter valid fax number"),
                                webAddress: Yup.string()
                                    .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, "Please enter valid web address")
                                    .required("Please enter web address")
                                    .max(250, "Web Address is too long - should be 250 characters maximum."),
                                emailAddress: Yup.string()
                                    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Please enter valid email address")
                                    .required("Please enter email address")
                                    .max(250, "Web Address is too long - should be 250 characters maximum."),

                            })}
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    setSubmitting
                                } = props;
                                return (
                                    <form noValidate onSubmit={handleSubmit} id="EditBasicInfoForm">

                                        <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                                                      <Box  mb={3}>
                                                          Edit Basic Info
                                                      </Box>
                                        </Typography>
                                        {alertDialog()}
                                        <Grid container spacing={2}>

                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    autoComplete="off"
                                                    name="employerName"
                                                    variant="outlined"
                                                    id="employerName"
                                                    fullWidth
                                                    defaultValue={data.employerName}
                                                    disabled
                                                    value={values.employerName}
                                                    name="employerName"
                                                    label="Employer Name"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.companyRegNumber}
                                                    fullWidth
                                                    name="companyRegNumber"
                                                    label="Company Registration Number"
                                                    id="regNumber"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.companyRegNumber && touched.companyRegNumber) && errors.companyRegNumber}
                                                    </Typography>
                                                    }
                                                    margin="normal"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={3}>
                                                <InputLabel id="titleCode" style={{marginTop: '24%',marginRight: '11%',}}> Employer Group* </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                               <FormControl variant="outlined" style={classes.selectBox} style={{marginTop: '16%'}}>
                                                    <RadioGroup  row aria-label="employerGroup" name="employerGroup" value={values.employerGroup} onChange={handleCheckChange}>
                                                        <FormControlLabel checked={employerGroup === "true"} value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel checked={employerGroup === "false"} value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>

                                            </Grid>

                                            <Grid item xs={12} sm={6}>

                                                <FormControl style={classes.selectBox}>
                                                    <TextField
                                                        type="text"
                                                        name="sectorName"
                                                        label="Sector Name*"
                                                        select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        value={values.sectorName}
                                                        helperText={<Typography
                                                            style={{color:'red',}}
                                                        >
                                                            {(errors.sectorName && touched.sectorName) && errors.sectorName}
                                                        </Typography>
                                                        }
                                                        margin="normal"
                                                    >
                                                        {sectorList.map(sector => (
                                                            <MenuItem key={sector.sectorName} value={sector.sectorName}>
                                                                {sector.sectorName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={3}>
                                                <InputLabel id="multinationalCompany" style={{marginTop: '24%',marginRight: '11%',}}> Multinational Company* </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>

                                                <FormControl variant="outlined" style={classes.selectBox} style={{marginTop: '16%'}}>
                                                    <RadioGroup  row aria-label="multinationalCompany" name="multinationalCompany" value={values.multinationalCompany}
                                                                 onChange={handleCheckChange}>
                                                        <FormControlLabel checked={multinationalCompany === "true"} value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel checked={multinationalCompany === "false"} value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>

                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl style={classes.selectBox}>
                                                    <TextField
                                                        type="text"
                                                        name="entityType"
                                                        label="Entity Type*"
                                                        select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        value={values.entityType}
                                                        helperText={<Typography
                                                            style={{color:'red',}}
                                                        >
                                                            {(errors.entityType && touched.entityType) && errors.entityType}
                                                        </Typography>
                                                        }
                                                        margin="normal"
                                                    >
                                                        {entityList.map(option => (
                                                            <MenuItem key={option.entityType} value={option.entityType}>
                                                                {option.entityType}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl style={classes.selectBox}>
                                                    <TextField
                                                        type="text"
                                                        name="operationalStatus"
                                                        label="Operational Status*"
                                                        select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        value={values.operationalStatus}
                                                        helperText={<Typography
                                                            style={{color:'red',}}
                                                        >
                                                            {(errors.operationalStatus && touched.operationalStatus) && errors.operationalStatus}
                                                        </Typography>
                                                        }
                                                        margin="normal"
                                                    >
                                                        {opStatusList.map(option => (
                                                            <MenuItem key={option.id} value={option.operationalStatus}>
                                                                {option.operationalStatus}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.vatNumber}
                                                    fullWidth
                                                    name="vatNumber"
                                                    label="VAT Number"
                                                    id="vatNumber"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.vatNumber && touched.vatNumber) && errors.vatNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.taxNumber}
                                                    fullWidth
                                                    name="taxNumber"
                                                    label="TAX Number"
                                                    id="taxNumber"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.taxNumber && touched.taxNumber) && errors.taxNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.noEmployed}
                                                    fullWidth
                                                    type="number"
                                                    name="noEmployed"
                                                    label="Number Employed*"
                                                    id="noEmployed"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.noEmployed && touched.noEmployed) && errors.noEmployed}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl style={classes.selectBox}>
                                                    <TextField
                                                        type="text"
                                                        name="financialYearEndMonth"
                                                        label="Financial Year End Month*"
                                                        select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        variant="outlined"
                                                        value={values.financialYearEndMonth}
                                                        helperText={<Typography
                                                            style={{color:'red',}}
                                                        >
                                                            {(errors.financialYearEndMonth && touched.financialYearEndMonth) && errors.financialYearEndMonth}
                                                        </Typography>
                                                        }
                                                    >
                                                        {monthList.map(option => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.employerNotes}
                                                    fullWidth
                                                    multiline
                                                    name="employerNotes"
                                                    label="Employer Notes*"
                                                    id="taxNumber"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.employerNotes && touched.employerNotes) && errors.employerNotes}
                                                        <div style={{color: values.employerNotes.length <= 1000?'black':'red', float: "right", fontSize: "10px"}}>
                                                            {values.employerNotes.length}/1000
                                                        </div>
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.natureOfBusiness}
                                                    fullWidth
                                                    name="natureOfBusiness"
                                                    label="Nature of Business*"
                                                    id="natureOfBusiness"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.natureOfBusiness && touched.natureOfBusiness) && errors.natureOfBusiness}
                                                        <div style={{color: values.natureOfBusiness.length <= 250?'black':'red', float: "right", fontSize: "10px"}}>
                                                            {values.natureOfBusiness.length}/250
                                                        </div>
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.telephoneNumber}
                                                    fullWidth
                                                    name="telephoneNumber"
                                                    label="Telephone Number*"
                                                    id="telephoneNumber"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.telephoneNumber && touched.telephoneNumber) && errors.telephoneNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.faxNumber}
                                                    fullWidth
                                                    name="faxNumber"
                                                    label="Fax Number"
                                                    id="faxNumber"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.faxNumber && touched.faxNumber) && errors.faxNumber}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.webAddress.toLowerCase()}
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.webAddress && touched.webAddress) && errors.webAddress}
                                                    </Typography>
                                                    }
                                                    fullWidth
                                                    id="webAddress"
                                                    label="Web Address*"
                                                    name="webAddress"
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.emailAddress.toLowerCase()}
                                                    fullWidth
                                                    name="emailAddress"
                                                    label="Email Address*"
                                                    id="emailAddress"
                                                    autoComplete="off"
                                                    helperText={<Typography
                                                        style={{color:'red',}}
                                                    >
                                                        {(errors.emailAddress && touched.emailAddress) && errors.emailAddress}
                                                    </Typography>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <br/>

                                        <div style={{marginBottom:'2%'}}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={classes.editSubmit}
                                                disabled={!setSubmitting}
                                            >
                                                SAVE
                                            </Button>

                                            <Button
                                                onClick = {handleClose}
                                                variant="contained"
                                                color="primary"
                                                style={classes.submit}
                                            >
                                                CANCEL
                                            </Button>

                                        </div>
                                        <div style={{textAlign: "center"}} height={50} width={40} >
                                            <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={isLoaded} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                        {/* END OF FORMIK */}
                    </main>
                </div>
            </Grid>
        </Grid>
);
};

class BasicInfoEditDialog  extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectorList: [],
            entityList: [],
            opStatusList: []
        };

    }

    handleClose = () => {
        this.props.onClose();
    };

    handleSave = () => {
        this.props.onSave();
    };

    async componentDidMount() {
        var that = this;
        ApiService.fetchAllListsForCreateEmployer()
            .then(axios.spread(function (sectorData, entityData) {
                const sectors = sectorData.data;
                const entities = entityData.data;
                that.setState({sectorList:sectors});
                that.setState({entityList:entities});
            }))
            .catch(function (error) {
                console.log(error);
            });

        ApiService.fetchAllOperationalStatus()
            .then((res) => {
                const opStatuses = res.data;
                that.setState({opStatusList:opStatuses});
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    render() {
        const empDetails = this.props.empBasicInfo;
        return (
            <div>
                <BasicInfoEditDialogComponent data = {empDetails} entityList = {this.state.entityList} onSave={() => {this.handleSave();}}
                                              onClose={() => {this.handleClose();}} sectorList = {this.state.sectorList} opStatusList = {this.state.opStatusList}/>
            </div>
        );
    }
}
export default BasicInfoEditDialog;
