import {makeStyles} from "@material-ui/core/styles/index";

const style = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
     row: {
        border: '2px solid rgb(257,257,257)',

    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(0,100,150,0.05)',
    },
    
  },
    searchButton: {
        float: 'right',
        backgroundColor: '#673ab7',
        marginTop: 15,
        marginRight: 40,
        '&:hover':{
            backgroundColor: 'rgb(126, 57, 251)',
        },
    },
    stopOrderBox: {
        /*margin: 1%;*/
        paddingTop: '1%',
        marginTop: '10px',
    },
});

export default style;