import axios from 'axios';
import * as Cookies from "js-cookie";
let API_URL;
let FLOW_URL;
let TRELLO_KEY;
let TRELLO_TOKEN;

const host = window && window.location && window.location.hostname;

if(host === 'localhost'){
    API_URL = 'https://localhost:9140/employerportalbackend';
    FLOW_URL = 'https://api-dev.getup.metropolitan.co.za/flow-api';
    TRELLO_KEY = 'd2b25f82d19812ea5e5f6b0e6a2d0f55';
    TRELLO_TOKEN = '8226bf5d8a1d39d5957702e4a6674f3bb30631997bbc1f01f79510cc9f39158c';
}else if (host === 'www.dev-markets.metgetup.co.za'){
    API_URL = 'https://api-dev.getup.metropolitan.co.za/employerportalbackend';
    FLOW_URL = 'https://api-dev.getup.metropolitan.co.za/flow-api';
    TRELLO_KEY = 'd2b25f82d19812ea5e5f6b0e6a2d0f55';
    TRELLO_TOKEN = '8226bf5d8a1d39d5957702e4a6674f3bb30631997bbc1f01f79510cc9f39158c';
}else if (host === 'www.pre-markets.metgetup.co.za'){
    API_URL = 'https://api-dev.getup.metropolitan.co.za/employerportalbackend-pre';
    FLOW_URL = 'https://api-dev.getup.metropolitan.co.za/flow-api';
    TRELLO_KEY = 'd2b25f82d19812ea5e5f6b0e6a2d0f55';
    TRELLO_TOKEN = '8226bf5d8a1d39d5957702e4a6674f3bb30631997bbc1f01f79510cc9f39158c';
}else if (host === 'www.markets.metgetup.co.za'){
    API_URL = 'https://api.getup.metropolitan.co.za/employerportalbackend';
    FLOW_URL = 'https://api.getup.metropolitan.co.za/flow-api';
    TRELLO_KEY = 'd2b25f82d19812ea5e5f6b0e6a2d0f55';
    TRELLO_TOKEN = '8226bf5d8a1d39d5957702e4a6674f3bb30631997bbc1f01f79510cc9f39158c';
}else {
    API_URL = 'https://api-external.metropolitan.co.za/employerportalbackend';
    FLOW_URL = 'https://api-dev.getup.metropolitan.co.za/flow-api';
}


const EMPLOYER_API_BASE_URL = API_URL + '/findEmployer';
const EMPLOYER_DETAILS_URL = API_URL + '/retriveEmployerDetails';
const EMPLOYER_SO_URL = API_URL + '/retriveSOInfo';
const ADD_EMPLOYER_URL = API_URL + '/createEmployer';
const EMPLOYER_NAME_CHECK_URL = API_URL + '/employerNameCheck';
const EMPLOYER_CONTACT_TITLES_URL = API_URL + '/getAllContactTitles';
const EMPLOYER_CONTACT_URL = API_URL + '/getAllContactJobTitles';
const EMPLOYER_SECTOR_URL = API_URL + '/getAllSector';
const EMPLOYER_ENTITY_URL = API_URL + '/getAllEmployerEntity';
const CREATE_ACTIVITY_URL = API_URL + '/createUserActivities';
const OPERATIONAL_STATUS_LKP_URL = API_URL + '/getAllEmployerOperationalStatus';
const MEDICAL_AID_URL = API_URL + '/getMedicalAidList';
const PENSION_FUND_URL = API_URL + '/getPensionFundList';
const RSA_PROVINCE_LKP_URL = API_URL + '/getRSAProvinceList';
const UPDATE_SOCIAL_MEDIA_URL = API_URL + '/createUpdateSocialMedia';
const UPDATE_BENEFITS_URL = API_URL + '/createUpdateBenefits';
const UPDATE_ADDRESS_URL = API_URL + '/createUpdateAddress';
const UPDATE_CONTACTS_URL = API_URL + '/createUpdateContact';
const UPDATE_EMPLOYER_INFO_URL = API_URL + '/updateEmployerInfo';
const DAY_TYPE_URL = API_URL + '/getDayTypeList';
const UPDATE_EMPLOYER_HOURS_URL = API_URL + '/createUpdateEmployerHours';
const CREATE_FLOW_ORGANIZATION_BOARD = FLOW_URL + '/trello/create-employer-nm-board';



const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};

let userSignOn = "";
let parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


class ApiService {


    fetchEmployerName(employerInput) {
        return axios.post(EMPLOYER_API_BASE_URL, employerInput, {headers:headers});
    }

    retriveEmployerDetails(employerInput) {
        var request = this.setActivityInput("employer_search");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity Employer Search :", res.data.toString())
            });
        return axios.post(EMPLOYER_DETAILS_URL, employerInput, {headers:headers} );
    }

    retriveSODetails(employerInput) {
        return axios.post(EMPLOYER_SO_URL, employerInput, {headers:headers});
    }

    addEmployer(employerInput) {
        var request = this.setActivityInput("create_employer");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity create employer :", res.data.toString())
            });
        return axios.post(ADD_EMPLOYER_URL, employerInput, {headers:headers});
    }

    addFlowEmployerBoard(newBoardRequest) {
        return axios.post(CREATE_FLOW_ORGANIZATION_BOARD, newBoardRequest, {headers:headers});
    }

    checkExistingEmployerName(employerInput) {
        return axios.post(EMPLOYER_NAME_CHECK_URL, employerInput, {headers:headers});
    }

    fetchAllListsForCreateEmployerContact() {
        return axios.all([
            axios.post(EMPLOYER_CONTACT_TITLES_URL, {headers:headers}),
            axios.post(EMPLOYER_CONTACT_URL, {headers:headers})
        ])
    }

    fetchBenefitsList() {
        return axios.all([
            axios.post(MEDICAL_AID_URL, {headers:headers}),
            axios.get(PENSION_FUND_URL, {headers:headers})
        ])
    }

    fetchAllListsForCreateEmployer() {
        return axios.all([
            axios.post(EMPLOYER_SECTOR_URL, {headers:headers}),
            axios.post(EMPLOYER_ENTITY_URL, {headers:headers})
        ])
    }

    createUserActivity(activityInput) {
        return axios.post(CREATE_ACTIVITY_URL, activityInput, {headers:headers});
    }

    fetchAllOperationalStatus() {
        return axios.post(OPERATIONAL_STATUS_LKP_URL, {headers:headers});
    }

    fetchAllDayTypes() {
        return axios.get(DAY_TYPE_URL, {headers:headers});
    }

    fetchRSAProvinceList() {
        return axios.get(RSA_PROVINCE_LKP_URL, {headers:headers});
    }

    createUpdateSocialMedia(socialMediaInput) {
        var request = this.setActivityInput("social_media_edit");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity update employer social media :", res.data.toString())
            });
        return axios.post(UPDATE_SOCIAL_MEDIA_URL, socialMediaInput, {headers:headers});
    }

    createUpdateBenefits(benefitsInput) {
        var request = this.setActivityInput("benefits_edit");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity update employer benefits :", res.data.toString())
            });
        return axios.post(UPDATE_BENEFITS_URL, benefitsInput, {headers:headers});
    }

    updateEmployerAddress(addressList) {
        var request = this.setActivityInput("address_edit");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity update employer address :", res.data.toString())
            });
        return axios.post(UPDATE_ADDRESS_URL, addressList, {headers:headers});
    }

    updateEmployerContacts(contactList) {
        var request = this.setActivityInput("contacts_edit");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity update employer contacts :", res.data.toString())
            });
        return axios.post(UPDATE_CONTACTS_URL, contactList, {headers:headers});
    }

    updateEmployerBasicInfo(employer) {
        var request = this.setActivityInput("basic_info_edit");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity update employer basic info :", res.data.toString())
            });
        return axios.post(UPDATE_EMPLOYER_INFO_URL, employer, {headers:headers});
    }

    updateEmployerOpHours(opData) {
        var request = this.setActivityInput("operating_hours_edit");
        this.createUserActivity(request)
            .then((res) => {
                console.log("User Activity update employer operating hours :", res.data.toString())
            });
        return axios.post(UPDATE_EMPLOYER_HOURS_URL, opData, {headers:headers});
    }


    setActivityInput(activity) {
        let activityInput = {
            userRole: "NA",
            userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber,
            activityName: activity
        };
        return activityInput;
    }

    getTrelloKey() {
        return TRELLO_KEY;
    }

    getTrelloToken() {
        return TRELLO_TOKEN;
    }

}

export default new ApiService();