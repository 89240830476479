import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import style from '../CaptureEmployer/style.js';
import ApiService from "../../service/ApiService";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MomentUtils from "@date-io/moment";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import {
    Formik
} from 'formik';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "@material-ui/pickers";
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import * as Cookies from "js-cookie";
const classes = style;

const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};


class contactEditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: props.empContacts.length < 1 ? [{empContactId: null, empId: props.employerId, titleCode:'',contactPersonFullName:'',
                birthOn:null, jobTitle:'', email:'', telephone:'', contactPersonFullNameError:'',
                titleCodeError:'', birthOnError:'', jobTitleError:'', emailError:'', telephoneError:'',
                delete: null, userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber}] : props.empContacts,
            titleCodes: [],
            jobTitles: [],
            isLoaded: false,
            employerID: props.employerId,
            removedContacts: [],
            alertOpen: false,
            alertTitle: '',
            alertText: '',
        };

    }
    handleClose () {
        this.props.onClose();
    };

    handleAlertOpen = () => {
        this.setState({alertOpen: true});
    };

    handleAlertClose = () => {
        this.setState({alertOpen: false});
    };

    alertDialog(title, text){
        return (
            <Dialog
                open={this.state.alertOpen}
                onClose={this.handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                style={{marginTop: '-25%'}}
            >
                <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleAlertClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    handleSubmit() {

        let that = this;
        let mandatoryErrorFlag= false;
        let validationErrorFlag = false;
        let contactListData = [...that.state.contacts];
        for(let j = 0; j< contactListData.length; j++) {
            contactListData[j].userSignOn = parseJwt(Cookies.get("auth")).sub.employeeNumber;
            if(contactListData[j].birthOn === "") {
                contactListData[j].birthOn = null;
            }
        }
        that.setState({contacts : contactListData});


        let contactList = this.state.contacts;
        for(let i = 0; i< contactList.length; i++) {
            let elem = contactList[i];
            if(elem.titleCode === '' || elem.contactPersonFullName === '' || elem.jobTitle === '' ||elem.email === '' || elem.telephone === '') {
                mandatoryErrorFlag = true;
            }
            else if(elem.titleCodeError !== "" || elem.contactPersonFullNameError !== "" || elem.jobTitleError !== "" ||elem.emailError !== ""
                || elem.telephoneError !== "" || elem.birthOnError !== "") {
                console.log("Here",elem );
                validationErrorFlag= true;
            }
            else {
                validationErrorFlag = false;
                mandatoryErrorFlag= false;
                console.log("elem date", elem.birthOn)
                if(elem.birthOn !== null && elem.birthOn !== "") {
                        //let dateElem = elem.birthOn.format('L');
                    let toDate = new Date(elem.birthOn);
                    let dd = toDate.getDate();
                    let mm = toDate.getMonth() + 1;
                    let yyyy = toDate.getFullYear();
                    if (dd < 10) {
                        dd = '0' + dd;
                    }
                    if (mm < 10) {
                        mm = '0' + mm;
                    }
                    let nDate = mm + '/' + dd + '/' + yyyy;
                    contactList[i].birthOn = nDate;
                }
            }
        }

        let valueArr = contactList.map(function(value){ return value.contactPersonFullName+value.jobTitle });
        let isDuplicate = valueArr.some(function(item, idx){
            return valueArr.indexOf(item) != idx
        });


        if(validationErrorFlag === true) {
            this.setState({alertText: 'Please check that all fields are correct and resave.'})
            this.setState({alertTitle: 'Error!'});
            this.handleAlertOpen();
        }
        else if(mandatoryErrorFlag === true) {
            this.setState({alertText: 'Please complete all the mandatory fields.'})
            this.setState({alertTitle: 'Error!'});
            this.handleAlertOpen();
        }
        else if(isDuplicate === true) {
            this.setState({alertText: 'You have duplicate contacts. Please correct and save.'})
            this.setState({alertTitle: 'Error!'});
            this.handleAlertOpen();
        }
        else{
            console.log("Form is validated! Submitting the form...", contactList);
            this.setState({isLoaded:true})

            ApiService.updateEmployerContacts(contactList)
                .then(res => {
                    let result = res;
                    this.setState({isLoaded:false})
                    if(result.data.errorStatus === false) {
                        this.props.onSave();
                        this.setState({alertText: result.data.responseMessage})
                        this.setState({alertTitle: 'Success!'});
                        this.handleAlertOpen();
                    }
                    else {
                        this.setState({alertText: result.data.responseMessage})
                        this.setState({alertTitle: 'Error!'});
                        this.handleAlertOpen();
                    }
                });
        }
    }


    async componentDidMount() {
        let that = this;
        ApiService.fetchAllListsForCreateEmployerContact()
            .then(axios.spread(function (titlesData, jobTitlesData) {
                that.setState({titleCodes: titlesData.data});
                that.setState({jobTitles : jobTitlesData.data});
            }))
            .catch(function (error) {
                console.log(error);
            });

    }

    handleContactChange(i, e) {
        const { name, value } = e.target;
        const errorName = name + 'Error';
        const regexNumber = /^[+0-9\b]+$/;

        let contacts = [...this.state.contacts];
        if( (name === "telephone" && !(regexNumber.test(value)) && value != '')) {
        }
        else {
            contacts[i] = {...contacts[i], [name]: value};
        }

        if(value ==="" || value == null ){
            contacts[i] = {...contacts[i], [errorName]: 'This field is mandatory'};
        }
        else if( name === "email" && !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ) ) {
            contacts[i] = {...contacts[i], [errorName]: 'Please enter valid email address'};
        }
        else if( name === "telephone" && !(/(^0|\+27)[1-9]{1}[0-9]{8}$/.test(value) ) ) {
            contacts[i] = {...contacts[i], [errorName]: 'Please enter valid contact number'};
        }
        else {
            contacts[i] = {...contacts[i], [errorName]: ''};
        }

        this.setState({ contacts });
    }

    handleTitleChange(i, e) {
        let contacts = [...this.state.contacts];
        contacts[i] = {...contacts[i], titleCode: e.target.value};
        if(e.target.value ==="" || e.target.value == null ){
            contacts[i] = {...contacts[i], titleCodeError: 'Please select title code'};
        }
        else {
            contacts[i] = {...contacts[i], titleCodeError: ''};
        }
        this.setState({ contacts });
    }

    handlejobTitleChange(i, e) {
        let contacts = [...this.state.contacts];
        contacts[i] = {...contacts[i], jobTitle: e.target.value};
        if(e.target.value ==="" || e.target.value == null ){
            contacts[i] = {...contacts[i], jobTitleError: 'Please select job position'};
        }
        else {
            contacts[i] = {...contacts[i], jobTitleError: ''};
        }
        this.setState({ contacts });
    }

    handleDateChange(i, date) {
        let toDate = new Date();
        let contacts = [...this.state.contacts];
        let diff = (toDate.getTime() - new Date(date).getTime())/ 1000;
        diff /= (60 * 60 * 24);
        //let yearDiff = Math.abs(Math.round(diff/365.25));
        let yearDiff = Math.round(diff/365.25);
        if(yearDiff >= 18) {
            contacts[i] = {...contacts[i], birthOnError: ''};
        }
        else if (toDate.getTime() - new Date(date).getTime() <= -0) {
            contacts[i] = {...contacts[i], birthOnError: 'Future date is not allowed'};
        }
        else if (yearDiff < 18 && yearDiff >= 0){
            contacts[i] = {...contacts[i], birthOnError: 'Contact person should be more than 18 years old'};
        }
        contacts[i] = {...contacts[i], birthOn: date};
        this.setState({ contacts });
    }

    addClick(){
        this.setState(prevState => ({
            contacts: [...prevState.contacts, {empContactId: null, empId: this.state.employerID, titleCode:'',contactPersonFullName:'',
                birthOn:null, jobTitle:'', email:'', telephone:'', contactPersonFullNameError:'',
                titleCodeError:'', birthOnError:'', jobTitleError:'', emailError:'', telephoneError:'',
                delete: null, userSignOn: parseJwt(Cookies.get("auth")).sub.employeeNumber}]
        }))
    }

    createUI(){
        return this.state.contacts.map((el, i) => {
            if(el.delete === null) {
                return(
            <div key={i}>
                {i != 0 && <hr/>}
                <br/>
               {/* <Grid item xs={12} md={6}>
                    <InputLabel style={classes.contactHeading}> CONTACT { + 1} </InputLabel>
                </Grid>*/}

                <br/> <br/>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" style={classes.selectBox}>
                            <InputLabel id="titleCode">
                                Contact Title*
                            </InputLabel>
                            <Select
                                MenuProps={{ style: classes.menu }}
                                value={el.titleCode.toUpperCase()}
                                name="titleCode"
                                onChange={this.handleTitleChange.bind(this, i)}
                                onBlur={this.handleTitleChange.bind(this, i)}
                                labelWidth={90}
                            >
                                { this.state.titleCodes.map(titleCode =>
                                    <MenuItem key={titleCode.titleId} value={titleCode.titleCode}>{titleCode.titleDescription}</MenuItem>
                                )}

                            </Select>
                        </FormControl>
                        <div style={classes.errorText} name="titleCodeError">
                            {el.titleCodeError}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="contactPersonFullName"
                            value={el.contactPersonFullName ||''}
                            label="Contact Full Name*"
                            name="contactPersonFullName"
                            autoComplete="new-password"
                            onBlur={this.handleContactChange.bind(this, i)}
                            onChange={this.handleContactChange.bind(this, i)}
                            inputProps={{
                                maxLength: 90,
                            }}
                        />
                        <div style={classes.errorText} name="contactPersonFullNameError">
                            {el.contactPersonFullNameError}
                        </div>
                    </Grid>

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid item xs={12} sm={2}>
                            <InputLabel style={{marginTop: '10%',marginRight: '11%',}}> Date Of Birth </InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DatePicker
                                placeholder="DD/MM/YYYY"
                                name="birthOn"
                                id="birthOn"
                                format={"DD/MM/YYYY"}
                                // handle clearing outside => pass plain array if you are not controlling value outside
                                mask={value =>
                                    value
                                        ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                                        : []
                                }
                                value={el.birthOn === "" ? null : el.birthOn}
                                onChange={this.handleDateChange.bind(this, i)}
                                disableOpenOnEnter
                                animateYearScrolling={false}
                                autoOk={true}
                                clearable
                                onInputChange={e => console.log("Keyboard:", e.target.value)}
                            />
                            <div style={classes.errorText} name="birthOnError">
                                {el.birthOnError}
                            </div>
                        </Grid>
                    </MuiPickersUtilsProvider>

                    <Grid item xs={12} sm={6}>

                        <FormControl variant="outlined" style={classes.selectBox}>
                            <InputLabel id="jobTitle">
                                Contact Job Position*
                            </InputLabel>
                            <Select
                                value={el.jobTitle}
                                MenuProps={{ style: classes.menu }}
                                name = "jobTitle"
                                onChange={this.handlejobTitleChange.bind(this, i)}
                                onBlur={this.handlejobTitleChange.bind(this, i)}
                                labelWidth={150}
                            >
                                { this.state.jobTitles.map(jobTitle =>
                                    <MenuItem value={jobTitle.jobTitle} key={jobTitle.jobTitleId}>{jobTitle.jobTitle}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <div style={classes.errorText} name="jobTitleError">
                            {el.jobTitleError}
                        </div>
                    </Grid>


                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={el.email ||''}
                            name="email"
                            label="Contact Email*"
                            id="email"
                            autoComplete="new-password"
                            onChange={this.handleContactChange.bind(this, i)}
                            onBlur={this.handleContactChange.bind(this, i)}
                            inputProps={{
                                maxLength: 90,
                            }}
                        />
                        <div style={classes.errorText} name="emailError">
                            {el.emailError}
                        </div>
                    </Grid>
                    <Grid item xs={12}  sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="telephone"
                            label="Contact Number*"
                            value={el.telephone ||''}
                            name="telephone"
                            autoComplete="new-password"
                            onBlur={this.handleContactChange.bind(this, i)}
                            onChange={this.handleContactChange.bind(this, i)}
                        />
                        <div style={classes.errorText} name="telephoneError">
                            {el.telephoneError}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {i!==0 && <IconButton aria-label="delete" style={classes.deleteContact} onClick={this.removeClick.bind(this, i)}>
                            <DeleteIcon  fontSize="large"/>
                        </IconButton>}
                    </Grid>

                </Grid>
                <br/>
            </div>
                )
            }})
    }

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.formErrors;
        const validity = this.state.formValidity;
        const isEmail = name === "email";
        const isPassword = name === "password";
        const emailTest = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : `${name} is required and cannot be empty`;
        if (validity[name]) {
            if (isEmail) {
                validity[name] = emailTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `${name} should be a valid email address`;
            }
            if (isPassword) {
                validity[name] = value.length >= 3;
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `${name} should be 3 characters minimum`;
            }
        }
        this.setState({
            formErrors: fieldValidationErrors,
            formValidity: validity
        });
    };

    removeClick= (i) =>{
        let contacts = [...this.state.contacts];
        let contactEmptyError = this.state.contactEmptyError;
        if(contacts[i].empContactId !== null){
            contacts[i] = {...contacts[i], delete: true};
        }
        else {
            contacts.splice(i, 1);
        }
        this.setState({contacts});
    }

    render() {

        return (
            <Grid container component="div">
                <Grid item md={10} style={{marginBottom: '25px', margin: '0 auto',marginTop: '4%'}}>
                    <div className="App">
                        <CssBaseline />
                        <main style={classes.popUpLayout}>
                            <Typography component="h1" variant="h5" align="left" style={classes.employerInfoHeading}>
                                <Box  mb={3}>
                                    Edit Employer Contacts
                                </Box>
                            </Typography>

                            {this.createUI()}
                            {this.alertDialog()}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick = {this.addClick.bind(this)}
                                style={classes.newContact}
                            >
                                Add New Contact
                            </Button>
                            <br/>
                            <br/>
                            <div style={{marginBottom:'2%',marginLeft: '30%',}}>
                                <Button
                                    onClick = {() => {this.handleSubmit();}}
                                    variant="contained"
                                    color="primary"
                                    style={classes.editSubmit}
                                >
                                    SAVE
                                </Button>

                                <Button
                                    onClick = {() => {this.handleClose();}}
                                    variant="contained"
                                    color="primary"
                                    style={classes.submit}
                                >
                                    CANCEL
                                </Button>

                            </div>

                            <div style={{textAlign: "center"}} height={50} width={40} >
                                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={this.state.isLoaded} />
                            </div>
                        </main>
                    </div>
                </Grid>
            </Grid>
        )}
}
export default contactEditDialog;
