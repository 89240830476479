import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import Button from '@material-ui/core/Button';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EditDialog from './addressEditDialog.js';
import Dialog from "@material-ui/core/Dialog/Dialog";
import Grid from "@material-ui/core/Grid/Grid";
import ApiService from "../../service/ApiService";

function TableInfo(props) {
    const classes = style();
    let empAddresses = props.data;
    const [openA, setOpenA] = React.useState(false);
    const [openB, setOpenB] = React.useState(false);
    const [openC, setOpenC] = React.useState(false);
    const [openD, setOpenD] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [fullWidth, setFullWidth] = React.useState(true);
    const physicalAddressConst = 'W';
    const postalAddressConst = 'P';
    const empId = props.employerId;
    let physicalAddressValues = {};
    let postalAddressValues = {};

    const handleClickSetEmpAddresses = () => {
        props.onRefresh();
    };

    const handleClickOpenA = () => {
       setOpenA(true);
    };
    const handleClickOpenB = () => {
        setOpenB(true);
    };
    const handleClickOpenC = () => {
        setOpenC(true);
    };
    const handleClickOpenD = () => {
        setOpenD(true);
    };

    const handleCloseA = () => {
        setOpenA(false);
    };
    const handleCloseB = () => {
        setOpenB(false);
    };
    const handleCloseC = () => {
        setOpenC(false);
    };
    const handleCloseD = () => {
        setOpenD(false);
    };


    let workAddress = "";
    let postalAddress = "";

    empAddresses.forEach(function(d) {
        if(d.addressTypeCode === 'W'){
            workAddress = [d.boxLineAddressLineOne, d.boxLineAddressLineTwo, d.boxLineAddressLineThree, d.buildingNumber, d.floorNumber,
                d.buildingName, d.streetNumber, d.streetName, d.cornerOf, d.suburb, d.town, d.provinceName, d.postalCode].filter(Boolean).join(", ");
            physicalAddressValues = d;
        }
        else if(d.addressTypeCode === 'P') {
            postalAddress = [d.boxLineAddressLineOne, d.boxLineAddressLineTwo, d.boxLineAddressLineThree, d.buildingNumber, d.floorNumber,
                d.buildingName, d.streetNumber, d.streetName, d.cornerOf, d.suburb, d.town, d.provinceName, d.postalCode].filter(Boolean).join(", ");
            postalAddressValues = d;
        }
    });

    return (
        <Paper className={classes.root}>
            <div style={{height:390}}>
    {/*<Button className={classes.edit} onClick={handleClickOpen}>
                Edit<EditRoundedIcon fontSize='small'/>
            </Button>*/}
            <Table  className={classes.table} aria-label="simple table">
                <br/>
                {empAddresses.length > 0 &&
                    <TableBody>
                  <TableRow style={{border: '2px solid rgb(257,257,257)'}}>
                    <TableCell component="th" scope="empAddress" style={{width:'35%'}}>
                        <span>Employer Physical Address</span>
                    </TableCell>

                    <TableCell align="left" style={{textTransform: 'capitalize'}}>

                        <div style={{display: 'inline-block'}}>:&nbsp;</div>
                        <div style={{display: 'inline-flex',width: '75%'}}>
                            {workAddress}
                        </div>
                        <div style={{display: 'inline-flex',}}>
                            <Button style={{marginLeft: '85%'}} onClick={handleClickOpenA}>
                                Edit<EditRoundedIcon fontSize='small'/>
                            </Button>

                            <Dialog disableBackdropClick open={openA} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleCloseA} aria-labelledby="form-dialog-title">
                                <Grid container component="main" >
                                    <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                                        <EditDialog empAddress={physicalAddressValues} addressTypeCode={physicalAddressConst} employerId = {empId} onClose={() => {handleCloseA();}} onSave={() => {handleClickSetEmpAddresses();}}/>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </div>
                    </TableCell>
                  </TableRow>

                  <TableRow  style={{backgroundColor: 'rgba(0,100,150,0.05)',}}>
                    <TableCell component="th" scope="empAddress" style={{width:'35%'}}>
                        <span>Employer Postal Address</span>
                    </TableCell>

                    <TableCell align="left" style={{textTransform: 'capitalize'}}>
                        <div style={{display: 'inline-block'}}>:&nbsp;</div>
                        <div style={{display: 'inline-flex',width: '75%'}}>
                        {postalAddress}
                        </div>
                        <div style={{display: 'inline-flex',}}>
                            <Button style={{marginLeft: '85%'}} onClick={handleClickOpenB}>
                                Edit<EditRoundedIcon fontSize='small'/>
                            </Button>

                            <Dialog disableBackdropClick open={openB} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleCloseB} aria-labelledby="form-dialog-title">
                                <Grid container component="main" >
                                    <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                                        <EditDialog empAddress={postalAddressValues} addressTypeCode={postalAddressConst} employerId = {empId} onClose={() => {handleCloseB();}} onSave={() => {handleClickSetEmpAddresses();}}/>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </div>
                    </TableCell>
                 </TableRow>
               </TableBody>
              }

                 {empAddresses.length <= 0 &&
                 <TableBody >
                 <TableRow className={classes.row}>
                 <TableCell component="th" scope="empAddress" style={{width:'35%'}}>
                     <span>Employer Physical Address</span>
                  </TableCell>
                 <TableCell align="left">:&nbsp;&nbsp;
                     <div style={{display: 'inline-flex', marginLeft: '75%'}}>
                         <Button style={{marginLeft: '85%'}} onClick={handleClickOpenC}>
                             Edit<EditRoundedIcon fontSize='small'/>
                         </Button>

                         <Dialog disableBackdropClick open={openC} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleCloseC} aria-labelledby="form-dialog-title">
                             <Grid container component="main" >
                                 <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                                     <EditDialog empAddress={physicalAddressValues} addressTypeCode={physicalAddressConst} employerId = {empId} onClose={() => {handleCloseC();}} onSave={() => {handleClickSetEmpAddresses();}}/>
                                 </Grid>
                             </Grid>
                         </Dialog>
                     </div>
                 </TableCell>
                 </TableRow>

                 <TableRow className={classes.row}>
                 <TableCell component="th" scope="empAddress" style={{width:'35%'}}>
                 <span>Employer Postal Address</span>
                 </TableCell>
                 <TableCell align="left">:&nbsp;&nbsp;
                     <div style={{display: 'inline-flex', marginLeft: '75%'}}>
                         <Button style={{marginLeft: '85%'}} onClick={handleClickOpenD}>
                             Edit<EditRoundedIcon fontSize='small'/>
                         </Button>

                         <Dialog disableBackdropClick open={openD} scroll={scroll} maxWidth={maxWidth} fullWidth={fullWidth} style={{maxHeight: '95%'}} onClose={handleCloseD} aria-labelledby="form-dialog-title">
                             <Grid container component="main" >
                                 <Grid item md={10} style={{ margin: '5% auto', border: '1px solid lightgrey'}}>
                                     <EditDialog empAddress={postalAddressValues} addressTypeCode={postalAddressConst} employerId = {empId} onClose={() => {handleCloseD();}} onSave ={() => {handleClickSetEmpAddresses();}}/>
                                 </Grid>
                             </Grid>
                         </Dialog>
                     </div>
                 </TableCell>
                 </TableRow>
                 </TableBody>}
            </Table>
        </div>
        </Paper>
    );
}

class SimpleTable  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empAddress : this.props.employerAddress,
        }
    }

    onRefresh = () => {
        let employerInput = {employerID: this.props.employerId};
        ApiService.retriveEmployerDetails(employerInput)
            .then((res) => {
             let address = res.data.employerAddress;
             this.setState({empAddress: address})
            });
    };

    render() {
        const empId = this.props.employerId;
        return (
            <div>
                <TableInfo data = {this.state.empAddress} employerId = {empId} onRefresh={() => {this.onRefresh();}}/>
            </div>
        );
    }
}
export default SimpleTable;
