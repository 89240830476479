import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from "@material-ui/core/styles";
import avatarService from './AvatarService';
import * as Cookies from "js-cookie";
import Home from "./../Employer";
import LoginImage from '../Images/NewMarkets2.png';
import Loader from 'react-loader-spinner';
import ApiService from "../../service/ApiService";



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright ©'}
    {' '}
    <Link color="inherit" href="https://www.metgetup.co.za/" target="_blank" rel="noopener noreferrer">
        Metropolitan GetUp
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
    </Typography>
);
}


const useStyles = theme => ({
    root: {
        height: '100vh',
        zIndex:'1030',
        '& .Mui-focused': {
            color: 'rgb(126,57,251)',
        },
        '& label.Mui-focused': {
            color: 'rgb(126,57,251)',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(126,57,251)',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(126,57,251)',
            },
        },
    },
    image: {
        backgroundImage: `url(${LoginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: '10%',
        margin: '10%',

    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),


    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height:'70px',
        backgroundColor: 'rgb(81, 80, 196)',
        color:'rgb(257,257,257)',
        '&:hover':{
            backgroundColor:'rgb(126,57,251)'
        },
    },

    newMarkets: {
        color: 'rgb(111, 66, 193)',
        margin: '9%',
        paddingTop: '50px',
    },
    errorMsg:{
        textAlign:'center',
        color:'red'
    }
});


class LoginDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.avatarService = new avatarService();
        this.state = {
            toHome: false,
            maskState: false,
            isLoaded: false,
            tr_key: '',
            tr_token: '',
            errMsg:'',
            buttonDisplay: false,
            showPassword:false,
        }
    }

    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }


handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword });
};

handleMouseDownPassword = event => {
    event.preventDefault();
};

handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    this.setState({ maskState: true, isLoaded: true });

    const user = { 'email': data.get('email'), 'password': data.get('password') };
    this.avatarService.GetUpAccessAD(user).then(resultGetUpAccess => {

        Cookies.set('auth', resultGetUpAccess.data.token, { expires: 2 });
    let uname = this.parseJwt(resultGetUpAccess.data.token).sub.mail;
    var base64Credentials = btoa(uname + ":" + user.password);
    sessionStorage.setItem("mmih_auth_token", resultGetUpAccess.data.token);
    localStorage.setItem('mmih_auth_token', resultGetUpAccess.data.token);
        let data = {
            username: this.parseJwt(resultGetUpAccess.data.token).sub.sAMAccountName,
            userFullName: this.parseJwt(resultGetUpAccess.data.token).sub.displayName,
            userSignOn: this.parseJwt(resultGetUpAccess.data.token).sub.employeeNumber,
        };

    if(data.userFullName !== "" && data.userFullName !== null) {
        this.setState({toHome: true});
        var activityInput = {
            userRole: "TST",
            userSignOn: data.userSignOn,
            activityName: "newmarkets_login"
        };
        ApiService.createUserActivity(activityInput)
            .then((res) => {
                console.log("Logged In")
            });
    }
    this.props.history.push("/");
},
    error => {
        this.setState({
            toHome: false,
            maskState: false,
            isLoaded: false,
            errMsg:'Login failed! Please validate your username/password.'
        });
    }
);
};
render() {
    const { classes } = this.props;
    if (this.state.toHome === true) {
        return <Home to='/home'/>
    }
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={3} md={7} className={classes.image}/>
            <Grid item xs={12} sm={9} md={5} component={Paper} elevation={6} square>
                <Typography component="h1" variant="h4" align="center" className={classes.newMarkets}>
                    NEW MARKETS
                </Typography>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5" align="left">
                        Sign In
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Username"
                            name="email"
                            autoComplete="new-password"
                            autoFocus
                            />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="new-password"
                            id="password"
                            type={this.state.showPassword ? 'text' : 'password'}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    aria-label="toggle password visibility"
                                     onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                        />
                        <Button

                            type="submit"
                            fullWidth
                            variant="contained" size = "large"
                            color="primary"
                            className={classes.submit}


                        >
                            LOGIN
                        </Button>
                            <InputLabel className={classes.errorMsg} htmlFor="my-input">{this.state.errMsg}</InputLabel>

                            <div style={{textAlign: "center"}} height={50} width={40} >
                                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={this.state.isLoaded} />
                            </div>

                            <Box mt={3}>
                                <Copyright />
                            </Box>
                    </form>
                </div>
            </Grid>
        </Grid>

);
}
} export default withStyles(useStyles)(LoginDetails);