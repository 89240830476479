import React, {Component} from 'react';
import { BrowserRouter, Route, } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import style from './style.js';
import CssStyle from './employerSOInfo.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

function createData(soInfo, soDetails, soAgencyNumber) {
    return { soInfo, soDetails, soAgencyNumber};
}
function stopOrderData(soName, soId) {
    return { soName, soId};
}

const rows = [
    createData('Stop Order Number:', 9312900, 52),
    createData('Position', 'Executive Director', 53),
    createData('Events', 'Employer activated', 54),
    createData('Field Name', 'Some data here', 55),
    createData('Field Name', 'Some data here', 56),
];



function SOTableInfo(props) {
    const classes = style();
    const employerSOInfo = props.data;
    let history = useHistory();
    let stopOrders = [];
    const [loaded, setLoaded] = React.useState(true);

    if(employerSOInfo.length > 0) {
        stopOrders = employerSOInfo.filter(function( obj ) {
            return obj.employerSODetails.agency_status === '001';
        });
    }

    const timeout = setTimeout(() => {
        setLoaded(false);
    }, 5000);

    function createStopOrder() {
        history.push('/createStopOrder')
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Paper className={classes.root}>
    {/*<div>
            <Button
            variant="contained"
            color="primary" onClick={createStopOrder}
            className={classes.searchButton}
            endIcon={<LocationCityIcon />}
            >
            CREATE
            </Button>
        </div>*/}
        <div style={{height:390}}  className={classes.stopOrderBox}>
            {loaded === true && <div>
                <Loader type="ThreeDots" color="#3f51b5" height={50} width={40} visible={loaded} />
            </div>}
        {stopOrders.length > 0 && loaded !== true ? (
            stopOrders.map(stopOrder => (
          <ExpansionPanel key={stopOrder.soAgencyNumber} style={{width:"90%", margin: "0 auto", background: "#f4f4f4", marginBottom: "15px",}}
                          expanded={expanded === stopOrder.soAgencyNumber} onChange={handleChange(stopOrder.soAgencyNumber)}>
             <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel1bh-content"
                 id="panel1bh-header"
             >
            <Typography className={classes.heading}>{stopOrder.soAgencyName}</Typography>
           </ExpansionPanelSummary>
         <ExpansionPanelDetails>
             <Table className={classes.table} aria-label="simple table">

                 <TableBody key={stopOrder.soAgencyNumber}>
                     <TableRow className={classes.row}>
                         <TableCell component="th" scope="contact">
                             Stop Order Agency Number
                         </TableCell>
                         <TableCell align="left">:&nbsp;&nbsp;{stopOrder.soAgencyNumber}</TableCell>
                     </TableRow>
                     <TableRow className={classes.row}>
                         <TableCell component="th" scope="contact">
                             Stop Order Agency Address
                         </TableCell>
                         <TableCell align="left">
                             <div style={{display: 'inline-block'}}>:&nbsp;&nbsp;</div>
                             <div style={{display: 'inline-grid',width: '98%'}}>{stopOrder.soAddress1}, {stopOrder.soAddress2}, {stopOrder.soAddress3}, {stopOrder.soAddress4} - {stopOrder.postalCode}</div>
                         </TableCell>
                     </TableRow>
                     <TableRow className={classes.row}>
                         <TableCell component="th" scope="contact">
                             Allow Premium Increase
                         </TableCell>
                         <TableCell align="left">:&nbsp;&nbsp;{stopOrder.employerSODetails.allow_premium_increase === "Y" ? 'Yes' : 'No'}</TableCell>
                     </TableRow>
                     <TableRow className={classes.row}>
                         <TableCell component="th" scope="contact">
                             Lodge Letter Required
                         </TableCell>
                         <TableCell align="left">:&nbsp;&nbsp;{stopOrder.employerSODetails.lodge_letter_required === "Y" ? 'Yes' : 'No'}</TableCell>
                     </TableRow>
                     <TableRow className={classes.row}>
                         <TableCell component="th" scope="contact">
                             Cancel Letter Required
                         </TableCell>
                         <TableCell align="left">:&nbsp;&nbsp;{stopOrder.employerSODetails.cancel_letter_required === "Y" ? 'Yes' : 'No'}</TableCell>
                     </TableRow>
                     <TableRow className={classes.row}>
                         <TableCell component="th" scope="contact">
                             Premium Increase Letter
                         </TableCell>
                         <TableCell align="left">:&nbsp;&nbsp;{stopOrder.employerSODetails.premium_increase_letter === "Y" ? 'Yes' : 'No'}</TableCell>
                     </TableRow>
                 </TableBody>
             </Table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
        ))) : (
            loaded !== true && <div>
                No stop order agency available.
            </div>
        )}
    </div>
    </Paper>
    );
}


class SOTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const empSODetails = this.props.employerSOInfo;
        return (
            <div>
                <SOTableInfo data = {empSODetails} />
            </div>
        );
    }
}

export default SOTable;